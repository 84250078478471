.records {
    .checkbox {
        position: relative;
        top: 10px;
        height: 95px;

        input[type='checkbox'] {
            display: none;
        }

        label {
            font-weight: 300;
            width: 100%;
            padding: 10px 0;
            display: inline-block;

            &:before {
                content: '';
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 3px;
                border: 1px solid $border;
                background-color: $white;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                vertical-align: middle;
                margin-right: 20px;
                cursor: pointer;
            }

            &:nth-child(3) {
                &:before {
                    border: transparent;
                }

                z-index: -1;
            }

            &.only-icon {
                width: 32px;
            }
        }

        input[type='checkbox']:checked + label:before {
            @include svg($icon-check, $green, 18px);
        }

        input[type='checkbox']:checked + label {
            color: $black;
        }

        &.search {
            input[type='checkbox'] + label {
                font-size: 1rem;
            }

            input[type='checkbox']:checked + label:before {
                @include svg($icon-check, $white, 18px);
                background-color: $green;
                background-color: var(--success-background-color);
            }
        }
    }

    .readonly {
        .checkbox {
            label {
                &:before {
                    cursor: auto;
                }
            }
        }
    }
}
