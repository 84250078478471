.fonda-datepicker-container {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    min-width: 260px;
    z-index: 800;
    background-color: $white;

    @include centered-shadow(0.5);

    .option-choose {
        height: 50px;
        width: 100%;
        padding: 5px;
        display: flex;

        span {
            width: 50%;
            background-color: $white;
            cursor: pointer;
            color: $black;
            height: 100%;
            display: inline-block;
            border: 1px solid $border;
            text-align: center;

            line-height: 34px;

            &.choosen {
                cursor: auto;
                background-color: $primary-color;
                color: $white;
            }
        }
    }

    .datepicker-container {
        min-width: 260px;
    }

    &.active {
        display: block;
    }

    .mydrp,
    .mydp {
        width: 100% !important;
        .inline,
        .inlinedp {
            width: 100% !important;
        }

        .selector {
            border: none;
            margin: 0 !important;
            height: 100% !important;
            &:focus {
                border: none;
            }
        }

        .titlearea {
            display: none;
        }

        .header {
            font-weight: bold;
            border-top: 1px solid $border;
            border-bottom: 1px solid $border;
            background: $white;

            height: 50px;

            tr {
                padding: 0 4px;
            }

            tr {
                td {
                    button {
                        background-color: $white;
                        color: $black;
                        &:hover {
                            background-color: $white;
                            color: $black;
                            text-decoration: underline;
                        }
                    }

                    &:first-child {
                        padding: 0;
                        > div {
                            width: 100%;
                            @include flex(space-between);
                            align-items: center;
                        }
                    }

                    &:nth-child(2) {
                        display: none;
                    }

                    &:nth-child(3) {
                        position: absolute;
                        margin: 0 calc(50% + 10px);
                        top: 0;
                        height: 50px;
                        left: 0;
                        @include flex(center);
                        align-items: center;
                        .headerbtncell {
                            display: none;
                        }
                    }
                }
            }
            .yearlabel {
                position: relative;
                top: 1px;
            }

            .headermonthtxt {
                margin-right: 30px;
            }

            .headermonthtxt,
            .headeryeartxt {
                display: flex;
            }

            .icon-mydpleft,
            .icon-mydrpleft {
                height: 50px;
                border-radius: 0;
                width: 50px;
                border-right: 1px solid $border;
                @include svg($icon-arrow-left, $primary-color, 15px);
                &:hover {
                    @include svg($icon-arrow-left, $primary-color-hover, 15px);
                }
                &:before {
                    content: '';
                }
            }

            .icon-mydpright,
            .icon-mydrpright {
                height: 50px;
                border-radius: 0;
                width: 50px;
                border-left: 1px solid $border;
                @include svg($icon-arrow-right, $primary-color, 15px);
                &:hover {
                    @include svg($icon-arrow-right, $primary-color-hover, 15px);
                }
                &:before {
                    content: '';
                }
            }
        }

        .caltable {
            display: block;

            tr {
                padding: 0 4px;
            }

            thead {
                display: block;
                th {
                    background-color: $white;
                    color: $black;
                    font-weight: bold;
                    width: 30px;
                    max-width: 30px;
                    height: 30px;
                    margin: 3px;
                    font-size: 1em;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    > div {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &:hover {
                        color: $black;
                        background-color: $white;
                    }
                }
            }

            tbody {
                display: block;
            }

            td {
                width: 30px;
                max-width: 30px;
                height: 30px;
                margin: 3px;
                display: inline-flex;
                padding: 0;
                justify-content: center;
                align-items: center;
                > div {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            tr {
                display: block;
                width: 100%;
            }
        }

        .datevalue {
            width: 100%;
            height: 100%;
            border-radius: 100% !important;
        }

        .currmonth {
            background-color: $white;
        }

        .daycell {
            height: 50px;
            border-radius: 100%;
            color: black;
        }

        .range {
            .datevalue {
                background-color: $blue-07;
                color: $black;
            }

            .selecteddaybegin,
            .selecteddayend {
                background-color: $primary-color;
                span {
                    color: $white !important;
                }
                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }

        .selecteddaybegin,
        .selecteddayend {
            background-color: $primary-color;
            span {
                color: $white !important;
            }
            &:hover {
                background-color: $primary-color-hover;
                color: $white;
            }
        }

        .selectedday {
            > div {
                background-color: $primary-color;
                span {
                    color: $white !important;
                }
                &:hover {
                    background-color: $primary-color-hover;
                }
            }
        }
    }
}

.fonda-datepicker-icon-container {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 100;
    .fonda-datepicker-icon {
        @include svg($icon-calendar, $primary-color, 15px);
        width: 15px;
        height: 15px;
        position: absolute;
        right: 5px;
        bottom: 12px;
        z-index: 10;
        cursor: pointer;
    }
}
