.landing-messages {
    .table-container {
        tr {
            &:hover {
                p {
                    color: white;
                }
            }
            td,
            th {
                p {
                    font-size: 1em;
                    font-weight: normal;
                    display: none;
                    &:first-child {
                        display: block;
                    }
                }
            }
        }
    }
}

.mat-progress-bar-fill::after {
    background-color: $primary-color-hover;
}

header {
    &.header-fixed {
        .header {
            a:not(.button) {
                color: $black;
            }
        }
    }

    form {
        a {
            color: #888888;
            &:hover {
                color: #888888;
            }
        }
    }
}

.form-item {
    margin-bottom: 10px;
}

.files {
    .full {
        ul {
            &.non-selectable {
                cursor: auto !important;
                &:hover {
                    background-color: $white !important;
                    li {
                        color: $content !important;
                    }
                }
            }
        }
    }
}

.second {
    .form-item {
        &.half {
            @media screen and (min-width: 768px) {
                margin-left: 30px;
            }
            margin-left: 0;
        }
    }
}

.admin-application-tools {
    .second {
        a {
            @media screen and (min-width: 768px) {
                margin-left: 30px;
            }
            &.full {
                margin-left: 0;
            }
        }
    }
}

a {
    cursor: pointer;
}

header {
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    opacity: 1;
    z-index: 9999;
    @include transition(0.7s);
    &.inactive {
        display: none;
    }

    @include breakpoint-min(768px) {
        &.top70 {
            top: 70px;
            height: calc(100% - 70px);
        }
    }
}

.actions-button {
    width: 100%;
    @include flex(space-between);
}

.fixed {
    //position: relative;
    @include breakpoint-min(768px) {
        position: fixed;
        top: -70px;
        z-index: 100;
        @include shadow(0.3);
    }
}

header {
    &.white-background {
        a {
            color: black;
        }
        .header .logo {
            @include logo(105px);
        }
    }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.application-flow {
    div {
        &.button-relative {
            position: relative;
            float: right;
        }
        &.button-fixed {
            position: fixed;
            right: 20px;
            top: 100px;
            z-index: 10;
        }
    }
}

a {
    &.color-red {
        background-color: $red;
        color: $white;
        border: none;
    }
    &:hover {
        &.color-red {
            background-color: $red-hover;
        }
    }
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.search-button {
    position: absolute;
    top: 80px;
    right: 50px;
    cursor: pointer;
}

.mat-ripple-element {
    display: none !important;
}

.mat-ink-bar {
    background-color: $primary-color;
}

button {
    &.button {
        padding: 8px 15px;
    }
}

.tokens-options-container {
    display: block !important;
    width: 262px !important;
    margin-top: 15px !important;
    label {
        display: block !important;
    }

    select {
        height: 28px;
        line-height: 28px;
        background-color: #fff;
        border: 1px solid #bcbcbc;
        padding: 3px 3px 3px 6px;
        outline: 0;
        border-radius: 2px;
        margin: 0 1px;
        box-sizing: border-box;
        width: calc(100% - 2px) !important;
    }
}
