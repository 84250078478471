.application-group {
    select-component {
        > div {
            border: 0;
            padding: 10px;
            padding-right: 32px;
        }
    }
}
.select-component {
    cursor: pointer;
    color: $black;

    display: inline-block;
    padding: 8px;
    padding-right: 32px;
    position: relative;
    background: transparent;
    border: 1px solid $border;
    @include transition(0.2s);
    &:after {
        @include transition(0.2s);
        content: '';
        @include svg($icon-arrow-down, $primary-color, 15px);
        position: absolute;
        width: 15px;
        height: 20px;
        right: 8px;
        top: 25%;
    }

    &:hover {
        background-color: $primary-color-hover;
        color: $white;
        &:after {
            @include svg($icon-arrow-down, $white, 15px);
        }
    }

    .option-component-container {
        @include centered-shadow(0.3);
        top: -1px;
        z-index: 11;
        left: -1px;
        position: absolute;
        min-width: 250px;
        max-height: 300px;
        overflow: auto;
        &:hover {
            background-color: $white;
            color: $black;
        }
        .option-component {
            * {
                font-weight: normal;
                color: $black;
                background-color: white;
            }

            color: $black;
            cursor: pointer;
            display: block;
            position: relative;
            padding: 9px;
            padding-right: 25px;
            background-color: white;
            z-index: 50;

            &.checkbox {
                background-color: white;
                width: 30px;
                height: 30px;
                border: 1px solid #d7d7d7;
                border-radius: 3px;
                position: relative;
            }
            &:hover {
                background-color: $primary-color-hover;
                color: $white;
                * {
                    color: $white;
                    background-color: $primary-color-hover;
                }
            }

            &.option-header {
                padding: 11px 25px 11px 9px;
                font-size: 0.9em;
                background-color: $alt-white;
                color: $inactive;
                &:hover {
                    background-color: $alt-white;
                    color: $inactive;
                }
                cursor: auto;
            }
        }

        &.right-placed {
            right: 0;
            left: auto;
        }
    }

    &.checkbox {
        .option-component-container {
            .option-component {
                @include flex(flex-start);
                padding-right: 12px;
                align-items: center;
                .checkbox-icon {
                    margin-right: 14px;
                    width: 20px;
                    min-width: 20px;
                    height: 20px;
                    border: 1px solid $border;
                }

                &.choosen {
                    .checkbox-icon {
                        @include svg($icon-approve, $white, 14px);
                        background-color: $green;
                        background-color: var(--success-background-color);
                    }
                }
            }
        }
    }

    &.expanded {
        background-color: $primary-color-hover;
        color: $white;
        &:after {
            @include svg($icon-arrow-down, $white, 15px);
        }
    }
}

.advanced-select-box-label {
    position: absolute;
    white-space: nowrap;
    top: -18px;
    color: $placeholder !important;
    font-size: 12px !important;
    left: 0;
    font-weight: 500;
    padding: 0 7px;
    visibility: hidden;
    &.visible {
        visibility: visible;
    }
}
