//
// Process steps
//
.process {
    width: 100%;
    padding: 60px 0 20px;
    background-color: $alt-white;
    display: table;

    h2 {
        text-align: center;
        margin-bottom: 50px;
    }

    .step {
        width: 100%;
        margin-bottom: 50px;

        h3 {
            font-size: 1em;
            padding-bottom: 15px;
            font-weight: 700;
        }
    }
}

@media screen and (min-width: 768px) {
    .process {
        .step {
            width: calc(50% - 15px);
            float: left;
            margin-left: 30px;
            margin-bottom: 40px;

            &:nth-child(2),
            &:nth-child(4) {
                margin-left: 0;
            }
        }
    }
}

@media screen and (min-width: 1050px) {
    .process {
        padding: 80px 0 40px;

        .step {
            width: calc(25% - 22.5px);

            &:nth-child(2) {
                margin-left: 0;
            }

            &:nth-child(4) {
                margin-left: 30px;
            }
        }
    }
}
