//
//  Cards
//
.cards {
    padding: 50px 0;
    background-color: $alt-border;

    h1,
    p {
        text-align: center;
    }

    h1 {
        font-size: 2em;
        padding-bottom: 15px;
    }

    p {
        padding-bottom: 30px;
    }

    .card-container {
        width: 100%;
        @include flex(space-between);
        align-items: center;
        flex-flow: row wrap;

        .card-option {
            width: 100%;
            height: 340px;
            padding: 30px;
            margin-bottom: 30px;
            @include flex(space-between);
            flex-direction: column;
            overflow: hidden;
            position: relative;
            @include shadow(0.3);
            @include transition(0.8s);
            color: $black;
            color: var(--success-color);

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                background: $green;
                background: var(--success-background-color);
                background-size: 110%;
                background-repeat: no-repeat;
                background-position: center center;
                z-index: 100;
                @include center(-50%, -50%);
                @include transition(0.8s);
            }

            &.large-cards {
                h2 {
                    margin-bottom: 115px;
                }

                div {
                    p {
                        width: 100%;
                    }
                }
            }

            h2 {
                font-size: 2em;
                color: inherit;
                z-index: 105;
            }

            div {
                z-index: 105;
                @include flex(space-between);
                align-items: flex-end;

                p {
                    margin-right: 10px;
                    text-align: left;
                    width: 100%;
                    color: inherit;
                    padding-bottom: 0;
                }
            }

            a {
                &.button {
                    background-color: transparent;
                    border: 1px solid $black;
                    border: 1px solid var(--success-color);
                    color: inherit;
                    &:hover {
                        color: $black;
                        color: var(--success-color-hover);
                        background-color: $green-hover;
                        background-color: var(--success-background-color-hover);
                    }
                }
            }
        }
    }
}

.application-schema-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    .application-schema-card-option {
        width: calc(33% - 15px);
        height: 340px;
        padding: 30px;
        @include flex(space-between);
        flex-direction: column;
        overflow: hidden;
        position: relative;
        @include shadow(0.3);
        @include transition(0.8s);
        color: $black;
        color: var(--success-color);

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: $green;
            background: var(--success-background-color);
            background-size: 110%;
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 100;
            @include center(-50%, -50%);
            @include transition(0.8s);
        }

        h2 {
            font-size: 2em;
            color: inherit;
            z-index: 105;
        }

        div {
            z-index: 105;
            @include flex(space-between);
            align-items: flex-end;

            p {
                margin-right: 10px;
                text-align: left;
                width: 100%;
                color: inherit;
                padding-bottom: 0;
            }
        }

        a {
            &.button {
                background-color: transparent;
                border: 1px solid $black;
                border: 1px solid var(--success-color);
                color: inherit;
                &:hover {
                    color: $black;
                    color: var(--success-color-hover);
                    background-color: $green-hover;
                    background-color: var(--success-background-color-hover);
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1049px) {
    .cards {
        h1 {
            font-size: 3rem;
        }

        .card-container {
            .card-option {
                width: calc(50% - 15px);
                margin-left: 30px;
                padding: 30px;

                &:nth-child(odd) {
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1050px) {
    .cards {
        .card-container {
            .card-option {
                width: calc(100% / 3 - 20px);

                &.large-cards {
                    width: calc(50% - 15px);
                    margin-left: 30px;
                }

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

                div {
                    @include flex(space-between);
                    width: 100%;
                }
            }
        }
    }
}

@include breakpoint-min(1250px) {
    .cards {
        padding: 60px 0;
    }
}
