.economy-section-container {
    padding: 40px 0 20px 0;

    form {
        .economy-plan {
            width: 100%;
            @include flex(space-between);
            align-items: baseline;
            .form-item {
                width: 30%;
            }
            .trash-btn {
                position: relative;
                bottom: -10px;
            }
        }

        .fonda-datepicker-icon {
            bottom: 32px;
        }
    }

    button {
        &.button {
            height: 40px;
            &.color-blue {
                margin: 0;
                margin-right: -10px;
            }

            &.new {
                margin-top: 20px;
            }
            &.disabled {
                &:hover {
                    background-color: $inactive;
                }
            }
        }
    }
}
