//
// Information bar
//
.application-info-bar {
    width: 100%;
    padding: 10px 0;

    .application-details {
        display: table;
        height: 50px;
        line-height: 50px;
        float: left;

        div {
            display: inline-block;
            font-weight: 700;
            color: $black;
            margin-right: 30px;
            font-size: 0.8em;

            span {
                color: $content;
                font-weight: 300;
            }
        }
    }

    .application-info-menu {
        float: right;

        &.landscape {
            display: none;
        }

        ul {
            li {
                position: relative;
                display: table;

                &:hover {
                    ul {
                        visibility: visible;
                        opacity: 1;
                        @include transition(0.5s);
                    }
                }

                ul {
                    opacity: 0;
                    visibility: hidden;
                    background-color: $white;
                    display: table;
                    z-index: 200;
                    @include center(-50%, -12%);
                    @include shadow(0.3);
                    @include transition(0.3s);

                    li {
                        width: 100%;

                        a {
                            padding: 10px 20px;
                            width: 100%;
                            display: block;

                            &:after {
                                display: none;
                            }

                            &:hover {
                                background-color: $primary-color;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .application-info-bar {
        .application-details {
            div {
                font-size: 1em;
            }
        }
    }

    .application-info-menu {
        &.landscape {
            display: block !important;
        }

        &.portrait {
            display: none;
        }
    }
}
