@mixin spacing($short, $property, $name, $value) {
    @each $directionName,
        $directionSuffixes
            in (
                '': (
                    '',
                ),
                't': (
                    '-top',
                ),
                'b': (
                    '-bottom',
                ),
                'l': (
                    '-left',
                ),
                'r': (
                    '-right',
                ),
                'x': (
                    '-left',
                    '-right',
                ),
                'y': (
                    '-top',
                    '-bottom',
                )
            )
    {
        .#{$short}#{$directionName}-#{$name} {
            @each $directionSuffix in $directionSuffixes {
                #{$property + $directionSuffix}: $value;
            }
        }
    }
}

$sizing: (
    '10': 10px,
    '20': 20px,
    '25': 25px,
    '30': 30px,
    'auto': auto,
);

@each $name, $value in $sizing {
    @include spacing('m', 'margin', $name, $value);
    @include spacing('p', 'padding', $name, $value);
}
