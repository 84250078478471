//
// Notifications
//
.notification-inbox {
    width: 100%;
    height: calc(100vh - 70px);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 70px;
    right: 0;
    margin-right: -400px;
    z-index: 2000;
    overflow-y: scroll;
    @include shadow(0.3);
    @include transition(0.5s);

    &.active {
        display: block;
        visibility: visible;
        opacity: 1;
        margin-right: 0;
        position: fixed;
        background-color: $white;
    }

    .notification-item {
        word-break: break-word;
        width: 100%;
        padding: 10px 30px 10px 20px;
        @include flex(justify-content);
        flex-flow: row wrap;
        background-color: $alt-border;
        border-top: 1px solid $border;
        position: relative;

        &.new {
            background-color: $alt-white;

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                background-color: $primary-color;
                border-radius: 100%;
                position: absolute;
                top: 25px;
                right: 20px;
            }
        }

        &:hover {
            background-color: $primary-color-hover;

            a {
                color: $white;
            }

            &.new {
                &:before {
                    background-color: $white;
                }
            }
        }

        .description {
            width: 100%;
            display: inline-block;
            padding-right: 10px;
        }

        .date {
            display: inline-block;
            width: 100%;
            text-align: left;
            font-size: 11px;
        }
    }
}

.notifications-count {
    height: 20px;
    line-height: 19px;
    border-radius: 12px;
    background-color: #2980b9;
    font-size: 0.7em;
    color: #ffffff;
    font-weight: 700;
    position: absolute;
    padding: 0 7px;
    right: -10px;
    text-align: center;
    top: -10px;
}

@media screen and (min-width: 768px) {
    .notification-inbox {
        width: 420px;
    }
}
