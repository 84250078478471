//
// Support pages
//
.support-page {
    width: 100%;
    height: 100%;
    min-height: 75vh;
    display: table;
    background-color: $alt-border;

    .support-wrapper {
        width: calc(100% - 40px);
        max-width: 800px;
        margin: 100px auto 30px;
        background-color: $white;
        padding: 30px 0;
        @include shadow(0.3);

        p {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
}

@media screen and (min-width: 768px) {
    .support-page {
        .support-wrapper {
            width: calc(100% - 60px);
            padding: 30px 0;
            margin: 140px auto 70px;
        }
    }
}

@media screen and (min-width: 1000px) {
    .support-page {
        .support-wrapper {
            padding: 50px 0;
        }
    }
}
