//
// List styles
//
.list {
    padding: 30px 0 50px;

    h2 {
        padding-bottom: 30px;
    }

    .list-wrapper {
        width: 100%;
        display: table;

        .list-header {
            display: none;
        }

        .list-item {
            word-break: break-word;
            width: 100%;
            margin-bottom: 20px;
            @include shadow(0.3);

            .list-item-buttons {
                @include flex(space-around);

                a {
                    width: 50%;
                    display: block;
                    padding: 25px 0;
                    text-align: center;
                    color: $black;
                    position: relative;
                    background-color: $white;

                    &:first-child {
                        border-right: 1px solid $border;
                    }

                    span {
                        color: $black;
                    }

                    &.list-item-buttons-messages {
                        .icon-message {
                            width: 20px;
                            height: 20px;
                            display: block;
                            @include svg($icon-mail, $black, 20px);
                            @include center(-50%, -50%);
                        }

                        .notifications {
                            display: table;
                            height: 24px;
                            min-width: 24px;
                            width: auto;
                            line-height: 24px;
                            border-radius: 12px;
                            background-color: $applicant-primary-color;
                            font-size: 0.8em;
                            color: $white;
                            font-weight: 700;
                            position: absolute;
                            padding: 0 8px;
                            left: 0;
                            top: 0;
                            margin: 15px 0 0 10px;
                        }
                    }

                    &:hover {
                        background-color: $applicant-primary-color;

                        span {
                            color: $white;
                        }
                    }

                    &.list-item-buttons-application {
                        margin-left: 5px;

                        .icon-arrow-right {
                            width: 20px;
                            height: 20px;
                            display: block;
                            @include svg($icon-arrow-right, $black, 16px);
                            @include center(-50%, -50%);
                        }

                        .refresh {
                            width: 40px;
                            height: 40px;
                            display: block;
                            @include center(-50%, -50%);
                            @include svg($icon-refresh, $black, 20px);
                        }

                        .edit {
                            width: 40px;
                            height: 40px;
                            display: block;
                            @include center(-50%, -50%);
                            @include svg($icon-pen, $black, 20px);
                        }
                    }

                    &:hover {
                        &.list-item-buttons-messages {
                            border: none;
                            background-color: $applicant-primary-color-hover;

                            .icon-message {
                                @include svg($icon-mail, $white, 20px);
                            }

                            .notifications {
                                background-color: $white;
                                color: $black;
                            }
                        }

                        &.list-item-buttons-application {
                            border: none;
                            background-color: $applicant-primary-color-hover;

                            .icon-arrow-right {
                                @include svg($icon-arrow-right, $white, 16px);
                            }
                            .refresh {
                                @include svg($icon-refresh, $white, 20px);
                            }
                            .edit {
                                @include svg($icon-pen, $white, 20px);
                            }
                        }
                    }
                }
            }
        }
    }

    &.user-applications-list {
        .list-wrapper {
            .list-item {
                .list-item-1,
                .list-item-2,
                .list-item-3,
                .list-item-4 {
                    padding: 15px;
                    border-bottom: 1px solid $border;

                    &:before {
                        color: $black;
                        padding-bottom: 5px;
                        display: block;
                        font-size: 0.8em;
                        content: attr(aria-label);
                    }
                }

                .list-item-1 {
                }

                .list-item-2 {
                    .list-item-process-bar {
                        display: none;
                    }
                }

                .list-item-3 {
                }

                .list-item-4 {
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .list {
        padding: 50px 0;

        .list-wrapper {
            @include shadow(0.3);

            .list-header {
                display: block;
                padding: 15px 0;

                h3 {
                    font-size: 0.8em;
                    font-weight: 400;
                    display: inline-block;

                    &:first-child {
                        padding-left: 15px;
                    }

                    &:last-child {
                        padding-right: 15px;
                        text-align: right;
                    }
                }
            }

            .list-item {
                font-size: 0.8em;
                margin-bottom: 0;
                box-shadow: none;
                padding: 15px 0;
                border-bottom: 1px solid $border;

                .list-item-1 {
                    padding-left: 15px;
                }

                .list-item-buttons {
                    text-align: right;
                    padding-right: 15px;
                    vertical-align: middle;
                    display: inline-block;
                    margin: 0;

                    a:not(:last-child) {
                        margin-right: 6px;
                    }

                    a {
                        padding: 15px 0;
                        max-width: 50px;
                        width: 50px;
                        height: 50px;
                        position: relative;
                        border: 1px solid $border;
                        border-radius: 100%;
                        background-color: transparent;
                        display: inline-block;

                        span {
                            display: none;
                        }

                        &.list-item-buttons-messages {
                            .notifications {
                                right: 0;
                                left: auto;
                                margin: -5px -5px 0 0;
                            }
                        }
                    }
                }
            }
        }

        &.user-applications-list {
            .list-header {
                h3 {
                    &:nth-of-type(1) {
                        width: calc(35% - 50px);
                        padding-left: 15px;
                    }

                    &:nth-of-type(2) {
                        width: calc(30% - 100px);
                        padding-left: 15px;
                    }

                    &:nth-of-type(3),
                    &:nth-of-type(4) {
                        width: 10%;
                    }

                    &:nth-of-type(5) {
                        width: 15%;
                    }
                }
            }

            .list-wrapper {
                .list-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;

                    .list-item-1,
                    .list-item-2,
                    .list-item-3,
                    .list-item-4 {
                        border-bottom: none;
                        padding: 0;

                        &:before {
                            display: none;
                        }
                    }

                    .list-item-1,
                    .list-item-2,
                    .list-item-3,
                    .list-item-4,
                    .list-item-buttons {
                        vertical-align: middle;
                        display: inline-flex;
                        margin: 0;
                    }

                    .list-item-1 {
                        width: calc(35% - 50px);
                        padding-left: 15px;
                    }

                    .list-item-2 {
                        width: calc(30% - 100px);
                        padding-left: 15px;
                    }

                    .list-item-3,
                    .list-item-4 {
                        width: 10%;
                    }

                    .list-item-5 {
                        width: 15%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .list {
        &.admin-template-list {
            .list-wrapper {
                .list-header {
                    @include flex(space-between);

                    h3 {
                        &:first-child {
                            width: calc(35% - 110px);
                        }

                        &:nth-child(2) {
                            width: 65%;
                        }

                        &:last-child {
                            width: 110px;
                        }
                    }
                }

                .list-item {
                    .list-item-1,
                    .list-item-2,
                    .list-item-buttons {
                        vertical-align: middle;
                        display: inline-block;
                    }

                    .list-item-1 {
                        width: calc(35% - 110px);
                    }

                    .list-item-2 {
                        width: 65%;

                        div {
                            display: inline-block;
                            margin-left: 15px;

                            &:first-child {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }

        &.admin-schedule-list {
            .list-wrapper {
                .list-header {
                    @include flex(space-between);

                    h3 {
                        width: 100%;

                        &:nth-child(1),
                        &:nth-child(2) {
                            max-width: 25%;
                        }

                        &:nth-child(3) {
                            max-width: 20%;
                        }

                        &:nth-child(4),
                        &:nth-child(5) {
                            max-width: 11%;
                        }
                        &:nth-child(6) {
                            max-width: 6%;
                        }

                        &:nth-child(7) {
                            max-width: 75px;
                        }
                    }
                }

                .list-item {
                    border-bottom: 1px solid $border;
                    @include flex-align(center);
                    @include flex(space-between);

                    div {
                        width: 100%;

                        &.list-item-1,
                        &.list-item-2 {
                            max-width: 25%;
                        }

                        &.list-item-3 {
                            max-width: 20%;
                        }

                        &.list-item-4,
                        &.list-item-5 {
                            max-width: 11%;
                        }

                        &.list-item-6 {
                            max-width: 6%;

                            .status {
                                margin-left: 15px;
                                width: 10px;
                                height: 10px;
                                border-radius: 100%;

                                &.active {
                                    background-color: $green;
                                    background-color: var(--success-background-color);
                                }

                                &.inactive {
                                    background-color: $placeholder;
                                }
                            }
                        }

                        &.list-item-buttons {
                            max-width: 125px;
                        }
                    }
                }
            }
        }

        &.admin-user-list {
            background-color: $alt-white;
            .user-list-nav {
                margin-bottom: 50px;
            }

            .list-wrapper {
                background-color: $white;
                .list-header {
                    @include flex(space-between);

                    h3 {
                        width: 100%;

                        &:nth-child(1) {
                            max-width: 20%;
                        }

                        &:nth-child(2) {
                            max-width: 40%;
                        }

                        &:nth-child(3) {
                            max-width: 15%;
                        }

                        &:nth-child(4) {
                            max-width: 10%;
                        }

                        &:nth-child(5) {
                            max-width: 125px;
                        }
                    }
                }

                .list-item {
                    border-bottom: 1px solid $border;
                    @include flex-align(center);
                    @include flex(space-between);

                    div {
                        width: 100%;

                        &.list-item-1 {
                            max-width: 20%;
                        }
                        &.list-item-2 {
                            max-width: 40%;
                        }
                        &.list-item-3 {
                            max-width: 15%;
                        }
                        &.list-item-4 {
                            max-width: 10%;
                        }
                        &.list-item-buttons {
                            max-width: 125px;
                        }
                    }

                    .list-item-3 {
                        * {
                            color: $content;
                            font-size: 0.8rem;
                            display: inline;
                        }
                    }
                }
            }
        }

        .list-item-buttons {
            width: 125px;

            a {
                .icon-edit {
                    width: 20px;
                    height: 20px;
                    @include svg($icon-pen, $black, 20px);
                    @include center(-50%, -50%);
                }

                &:hover {
                    border-color: $applicant-primary-color;

                    .icon-edit {
                        @include svg($icon-pen, $white, 20px);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .list {
        &.user-applications-list {
            .list-wrapper {
                .list-header {
                    h3 {
                        &:nth-of-type(1) {
                            width: calc(35% - 50px);
                            padding-left: 15px;
                        }

                        &:nth-of-type(2) {
                            width: calc(30% - 100px);
                            padding-left: 15px;
                        }

                        &:nth-of-type(3),
                        &:nth-of-type(4) {
                            width: 10%;
                        }

                        &:nth-of-type(5) {
                            width: 15%;
                        }
                    }
                }

                .list-item {
                    .list-item-1 {
                        width: calc(38% - 15px);
                        padding-left: 15px;
                    }

                    .list-item-2 {
                        .list-item-process-bar,
                        .list-item-process-percentage {
                            float: left;
                        }

                        .list-item-process-bar {
                            width: 80%;
                            height: 4px;
                            background-color: $border;
                            position: relative;
                            display: block;
                            margin-top: 6px;

                            &:before {
                                content: '';
                                height: 4px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                background-color: $green-hover;
                                background-color: var(--success-background-color-hover);
                            }

                            &.list-item-process-bar-status-20 {
                                &:before {
                                    width: 20%;
                                }
                            }

                            &.list-item-process-bar-status-done {
                                &:before {
                                    width: 100%;
                                }
                            }
                        }

                        .list-item-process-percentage {
                            width: 20%;
                            padding-left: 15px;
                        }
                    }

                    .list-item-1 {
                        width: calc(35% - 50px);
                        padding-left: 15px;
                    }

                    .list-item-2 {
                        width: calc(30% - 100px);
                        padding-left: 15px;
                    }

                    .list-item-3,
                    .list-item-4 {
                        width: 10%;
                    }

                    .list-item-5 {
                        width: 15%;
                    }
                }
            }
        }
    }
}
