@mixin dialogue() {
    width: 100%;
    min-height: 300px;
    padding: 15px;
    background-color: $alt-border;
    border: 1px solid $border;
    display: inline-block;
    overflow: auto;

    .message {
        display: inline-block;
        position: relative;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 30px;
        min-width: 150px;

        .correspondence-file {
            padding-top: 10px;
            .file {
                width: 100%;
            }
            a {
                cursor: pointer;
                width: 100%;
                &:hover {
                    color: $primary-color-hover;
                }
                &.user {
                    color: $white;
                    opacity: 0.7;
                    &:hover {
                        opacity: 1;
                    }
                }
                &.other {
                    color: $black;
                    opacity: 0.7;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        h3 {
            font-size: 1em;
            padding-bottom: 15px;
        }

        p {
            display: block;
            word-wrap: break-word;
            overflow-wrap: break-word;
            /* width: 100%; */

            white-space: normal;
            font-size: 0.8em;
            p {
                font-size: 1em;
            }
        }

        span {
            font-size: 0.8em;
            position: absolute;
            bottom: 0;
            margin-bottom: -20px;
            white-space: nowrap;
        }

        .file-icon {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            float: left;
            cursor: pointer;
            margin-right: 15px;

            .file-download {
                width: 100%;
                height: 100%;
                background-image: inline-color-svg($icon-download, $primary-color);
                background-size: 30px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &.other {
            background-color: $white;

            h3,
            p {
                color: $black;
            }

            span {
                margin-left: -15px;
            }
        }

        &.user {
            background-color: $primary-color;

            h3,
            p {
                color: $white;
            }
            span {
                right: 0;
            }
        }
    }

    //The only way to avoid float
    div {
        &.align-right {
            width: 100%;
            text-align: right;
        }
    }
}
