//
// User application flow
//
.application-bar {
    padding: 20px 0;
    background-color: $alt-white;
    position: relative;
    z-index: 2;

    nav {
        ul {
            &.application-template-type {
                text-align: center;
            }

            &.application-flow {
                width: auto;
                max-width: 800px;
                display: table;
                margin: 30px auto;

                li {
                    display: inline-block;
                    margin-left: 15px;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        font-size: 1em;
                        color: $inactive;
                        position: relative;

                        .notification {
                            display: table;
                            width: auto;
                            min-width: 24px;
                            max-width: 100px;
                            height: 24px;
                            line-height: 24px;
                            border-radius: 12px;
                            color: $white;
                            position: absolute;
                            z-index: 10;
                            margin-top: -5px;
                            margin-left: 30px;
                            @include transition(0.5s);

                            &.error {
                                background-color: $red;
                            }

                            div {
                                min-width: 24px;
                                max-width: 100px;
                                padding: 0 8px;
                                line-height: 23px;
                                text-align: center;
                                font-size: 0.8em;
                                font-weight: 700;
                                @include transition(0.5s);
                            }
                        }

                        .flow-circle,
                        .flow-text {
                            float: left;
                        }

                        .flow-circle {
                            position: relative;
                            width: 45px;
                            height: 45px;
                            background-color: $white;
                            border-radius: 100%;

                            .flow-step {
                                font-weight: 700;
                                @include center(-50%, -50%);
                            }
                        }

                        .flow-text {
                            display: none;
                        }

                        &.active,
                        &:hover {
                            .flow-circle {
                                background-color: $applicant-primary-color;

                                .flow-step {
                                    color: $white;
                                }
                            }

                            .flow-text {
                                color: $black;
                            }
                        }
                    }
                }
            }

            &.application-options {
                display: table;
                margin: 0 auto;

                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 10px;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        min-width: 50px;
                        height: 50px;
                        line-height: 27px;
                        border: 1px solid $border;
                        display: inline-block;
                        border-radius: 3px;
                        position: relative;

                        .icon-trash {
                            width: 20px;
                            height: 20px;
                            @include svg($icon-trash, $black, 16px);
                            @include center(-50%, -50%);
                        }
                    }
                    .notifications {
                        width: 24px;
                        height: 24px;
                        background-color: $applicant-primary-color;
                        font-size: 0.8em;
                        color: $white;
                        position: absolute;
                        right: -12px;
                        top: -12px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 10;
                    }
                }
            }
        }
    }
    &.application-overview-sections {
        nav {
            ul {
                li {
                    margin-left: 15px;
                    vertical-align: top;
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        .flow-text {
                            visibility: hidden;
                            opacity: 0;
                            display: none;
                        }
                        &.active {
                            .flow-text {
                                visibility: visible;
                                opacity: 1;
                                display: block;
                            }
                            &:after {
                                width: 60px;
                            }
                        }

                        &:after {
                            content: '';
                            width: 30px;
                            height: 1px;
                            background-color: $border;
                            display: inline-block;
                            position: relative;
                            top: 7px;
                            margin-left: 15px;
                        }
                    }

                    &:last-child {
                        a {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

.application-template,
.page-content-center {
    width: 100%;
    max-width: 1000px;
    margin: 60px auto;

    h1,
    p {
        text-align: center;
    }

    p {
        padding: 20px 0;

        span {
            &.icon-form-error {
                color: $red;
            }

            &.icon-form-lock {
                height: 16px;
                width: 16px;
                display: inline-block;
                position: relative;
                top: 2px;
                @include svg($icon-lock, $black, 1em);
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .application-bar {
        position: absolute;
        &.first-section {
            margin-top: 0;
        }
        top: 130px;
        nav {
            ul {
                &.application-flow {
                    li {
                        margin-left: 30px;

                        a {
                            display: flex;
                            .flow-text {
                                height: 50px;
                                line-height: 50px;
                                padding-left: 10px;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .application-template,
    .page-content-center {
        width: calc(100% - 60px);
    }
    .application-template {
        margin-top: 380px;
    }
}

@media screen and (min-width: 1150px) {
    .application-bar {
        nav {
            @include flex(space-between);
            ul {
                &.application-template-type,
                &.application-flow,
                &.application-options {
                    width: 100%;
                    margin: 0;
                }
                &.application-template-type {
                    text-align: left;
                }
                &.application-flow {
                    display: flex;
                    justify-content: center;
                }
                &.application-flow-left {
                    justify-content: left;
                }
                &.application-options {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
    }
    .application-template {
        margin-top: 284px;
    }
}
