//
// Administration menu
//
.administration-menu {
    width: 100%;
    background-color: $alt-white;
    display: table;

    ul {
        &.menu {
            width: 100%;
            @include flex(space-between);
            flex-flow: row wrap;

            li {
                padding: 25px 0;
                display: inline-block;

                &:first-child {
                    margin-left: 0;
                }

                a {
                    opacity: 0.6;
                    color: $black;
                    &.active {
                        opacity: 1;
                        font-weight: 700;
                    }

                    &:hover {
                        opacity: 1;
                    }

                    &.hidden {
                        display: none;
                    }
                }
            }
        }

        &.application-options {
            float: right;
            margin-top: 12px;
            display: table;

            li {
                display: inline-block;
                > a,
                select-component {
                    margin-left: 10px;
                }

                a {
                    span {
                        font-weight: 300;
                        color: $content;
                    }

                    &.color-blue {
                        @include shadow(0.3);
                    }
                }
            }
        }
    }
}
