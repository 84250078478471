.toast {
    min-width: 320px !important;

    .toaster-icon {
        display: none;
    }
}
.toast-top-right {
    top: 75px !important;
}
#toast-container {
    &:hover {
        cursor: auto;
    }
    .toast-success {
        background-color: $white;
        .toast-content {
            .toast-title {
                color: $green-darkest;
            }
        }
    }

    .toast-error {
        background-color: $white;
        .toast-content {
            .toast-title {
                color: $red;
            }
        }
    }

    .toast-warning {
        background-color: $white;
    }
}

#toast-container {
    > div {
        padding: 16px 20px !important;
        opacity: 1 !important;
    }

    .toast-content {
        cursor: auto;
        color: $black;

        .toast-title {
            margin: 4px 0;
            padding-right: 30px;
        }
    }

    div {
        &.toast-close-button {
            position: absolute;
            top: 10px;
            right: 13px;
            width: 35px;
            @include transition(0.2s);
            opacity: 1;
            height: 35px;
            border-radius: 3px;
            border: 1px solid $border;
            .toast-close-button {
                font-size: 0;
                position: relative;
                display: block;
                width: 20px;
                height: 20px;
                @include svg($icon-cross, $black, 12px);
                @include center(-50%, -50%);
                @include transition(0.2s);
                opacity: 1;
            }
            &:hover {
                opacity: 1;
                background-color: $primary-color-hover;
                .toast-close-button {
                    @include svg($icon-cross, $white, 12px);
                    opacity: 1;
                }
            }
        }
    }
}
