.column-picker {
    background-color: $white;
    &.popup {
        overflow: auto;
        width: 900px;
        padding: 20px;
        header {
            @include flex(space-between);
            align-items: center;
            margin-bottom: 20px;
        }

        h3 {
            margin-bottom: 30px;
        }

        section {
            margin-bottom: 30px;
        }

        #save {
            text-align: center;
            button {
                &.button {
                    &.color-blue {
                        padding: 13px 50px;
                    }
                }
            }
        }

        #add-filter {
            @include flex(space-between);
            width: 100%;
            white-space: nowrap;
            .big {
                min-width: 250px;
                .select-component {
                    width: 100%;
                    height: 100%;

                    .option-component-container {
                        top: 38px;
                        width: 100%;
                    }
                }
            }

            button {
                &.button {
                    &.color-blue {
                        padding: 0px 50px;
                        &.big {
                            width: 30%;
                            min-width: 250px;
                            padding: 8px 15px;
                        }
                    }
                }
            }
        }

        table {
            @include table;
            tr {
                th {
                    text-align: center;
                    padding-top: 10px;
                }
            }
        }
        @include centerfixed(-50%, -50%);

        .list-container {
            @include shadow(0.7);

            ul {
                .trash-icon {
                    border: 1px solid $border;
                    border-radius: 3px;
                    @include transition(0.5s);
                    @include svg($icon-trash, $black, 15px);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;
                    &:hover {
                        border-color: $primary-color-hover;
                        background-color: $primary-color-hover;
                        @include svg($icon-trash, $white, 15px);
                    }
                }
                .burger-button {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    display: block;
                    .icon-burger {
                        width: 16px;
                        height: 16px;
                        @include svg($icon-burger, $black, 16px);
                        @include center(-50%, -50%);
                    }
                }
                li {
                    padding: 10px;
                    width: 100%;
                    @include flex(space-between);
                    div {
                        display: inline-block;
                        height: 30px;
                        &:first-child {
                            width: 100px;
                        }

                        &:nth-child(2) {
                            width: 100px;
                        }

                        &:nth-child(3) {
                            width: calc(100% - 300px);
                        }

                        &:last-child {
                            width: 70px;
                        }
                    }
                    border-bottom: 1px solid $border;
                }

                &:first-child {
                    li {
                        border-bottom: none;
                    }
                }
            }

            > div {
                height: 50px;
                @include flex(center);
                align-items: center;
                background-color: $border;
                width: 100%;
            }
        }
    }

    .gu-over {
        border: 2px dotted $green;
        border: 2px dotted var(--success-background-color);
    }

    .gu-transit {
        opacity: 0.5;
    }
}
