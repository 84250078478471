.agenda {
    .edit-container {
        @include shadow(0.5);
        &.no-shadow {
            @include shadow(0);
        }
    }

    .caseworker-container {
        ul {
        }
    }
}
li {
    &.agenda {
        display: block;
        .list-container {
            width: 100%;
            height: inherit;
            @include flex(space-between);
            align-items: center;
            > div {
                height: inherit;
                &.drag-handle {
                    width: 100px;
                }
                &.actions {
                    @include flex(center);
                    text-align: center;
                    width: 100px;
                    div {
                        margin-left: 10px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
                &.project-title {
                    width: calc(100% - 250px);
                }
            }
            padding: 10px;

            &.file-table-opened {
                padding-bottom: 0;
            }
        }
        .checkbox {
            top: 0;
            display: inline-block;
            height: inherit;
            input[type='checkbox'] {
                display: none;
            }
            label {
                font-weight: 300;
                width: 100%;
                padding: 0;
                top: 0;
                display: inline-block;

                &:before {
                    content: '';
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    border-radius: 3px;
                    border: 1px solid $border;
                    background-color: $white;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    vertical-align: middle;
                    margin: 0;
                    cursor: pointer;
                }
            }

            input[type='checkbox']:checked + label:before {
                background-color: $green;
                background-color: var(--success-background-color);
                @include svg($icon-check, $white, 18px);
            }
        }
    }
}

.gu-transit {
    &.agenda {
        .agenda-files-container {
            display: none;
        }
        div {
            > div {
                display: block;
            }
        }
    }
}

.gu-mirror {
    &.agenda {
        .agenda-files-container {
            display: none;
        }
        .notifications-count {
            display: none;
            opacity: 0;
        }
    }
}
