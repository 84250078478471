.cookies-container {
    background-color: $white;
    position: fixed;
    z-index: 1000;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px;
    @include shadow(0.5);

    h2 {
        margin: 0 0 1rem 0;
    }
    p {
        margin: 0;
    }

    .wrapper {
        display: block;
        align-items: center;
        .message {
            width: 100%;
            margin-bottom: 15px;
            p,
            h2 {
                color: $black;
            }
        }

        .button-container {
            width: 100%;
            text-align: right;
            button {
                font-size: 1rem;
                margin: 0;
                width: 200px;
                cursor: pointer;
                background-color: transparent;
                color: $black;
                padding: 15px 0;
                border: 1px solid $applicant-primary-color-hover;
                &:hover {
                    background-color: $green-hover;
                    background-color: var(--success-background-color);
                    border-color: $applicant-primary-color-hover;
                }
            }
        }
    }

    @include breakpoint-min(1024px) {
        padding: 20px;
        .wrapper {
            @include flex(space-between);
            .message {
                margin-bottom: 0;
                width: 75%;
            }
            .button-container {
                width: 200px;
            }
        }
    }
}
