.records {
    &.readonly {
        p {
            font-size: 0.9em;
            color: $content;
        }
    }

    a {
        &.form-item {
            &.second {
                @include breakpoint-min(768px) {
                    margin-left: 30px;
                }
            }
        }
    }
}
