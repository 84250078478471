//
// Communication / Application options
//
// .communication {
// 	width: 100%;
// 	float: right;
// 	position: absolute;
// 	top: 70px;
// 	left: 0;
// 	z-index: 200;
// 	visibility: hidden;
// 	opacity: 0;
// 	display: none;
// 	overflow: hidden;
// 	@include transition(0.5s);

// 	&.communication-active {
// 		visibility: visible;
// 		opacity: 1;
// 		display: table;
// 	}
.communication {
    width: 100%;
    overflow: visible;
    .no-messages {
        text-align: center;
        @include center(-50%, -50%);
        width: 180px;
        color: $inactive;
        .icon-paperplane {
            width: 180px;
            height: 120px;
            margin-bottom: 40px;
            @include svg($icon-paperplane, $border, 180px);
        }

        a {
            margin-top: 30px;
        }
    }
    .communication-head {
        width: 100%;
        padding: 20px 5px;
        background-color: $white;
        @include flex(space-between);

        border-bottom: 1px solid #cccccc;
        > div {
            //padding-right: 70px;
            @include flex(space-between);
            align-items: center;
            h1 {
                margin-bottom: 0;
            }

            ul {
                display: flex;
                li {
                    display: inherit;

                    margin-left: 10px;
                    &:first-child {
                        margin-left: 0;
                    }
                    //> a, select-component {
                    //	margin-left: 10px;
                    //}

                    .close-btn {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 40px;
                    }

                    .icon-refresh {
                        width: 40px;
                        height: 40px;
                    }

                    .button {
                        line-height: 1.8;
                        padding: 5px 10px;
                    }

                    .refresh {
                        background-size: 15px;
                    }

                    .icon-close {
                        background-size: 15px;
                    }
                }
            }
        }

        div {
            vertical-align: top;

            h2,
            a {
                display: initial;
            }

            h2 {
                position: relative;
            }

            a {
                &.button {
                    //margin-left: 5px;
                    //margin-right: 5px;
                }

                &.close-btn {
                    display: block;
                }
            }
        }
    }

    .communication-inbox {
        width: 100%;
        height: calc(100vh - 70px);
        display: block;
        overflow-y: auto;

        .communication-message-item {
            &-seen {
                background-color: $lightgray;
                & > span > span:not(.message-text) {
                    color: $black-grey;
                }
            }
            &-not-seen > span {
                font-weight: bold;
                & > span:not(.message-text) {
                    font-weight: bold;
                }
            }
            & > span {
                flex: 1;
                text-align: left;
            }
            & > span:nth-child(2) {
                flex: 2;
                padding: 0em 0.25em;
                overflow: hidden;
                white-space: nowrap;
                display: flex;
                align-items: center;
            }
            & > span:nth-child(3) {
                text-align: right;
            }
            &.communication-message-titles {
                display: none;
                &:hover {
                    span {
                        color: $black;
                    }
                }
            }
            padding: 20px 5px;
            background-color: $white;
            border-bottom: 1px solid $border;
            position: relative;
            cursor: pointer;
            display: flex;
            @include transition(0.5s);

            p {
                &:first-child {
                }

                &:nth-of-type(2) {
                    display: none;
                    padding-right: 5px;
                }
            }

            &.new-message {
                background-color: $alt-white;
                font-weight: 400;
                color: $black;

                p {
                    &:first-child {
                        &:before {
                            content: '';
                            width: 6px;
                            height: 6px;
                            background-color: $primary-color;
                            border-radius: 100%;
                            position: absolute;
                            top: 29px;
                            left: 30px;
                        }
                    }
                }
            }

            &:hover {
                background-color: $primary-color-hover;
                span,
                span > span {
                    color: $white;
                }
                &.new-message {
                    p {
                        &:first-child {
                            &:before {
                                background-color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &.landing {
        .communication-head {
            > div {
                align-items: center;
                h2 {
                    padding: 0;
                }
            }
        }
    }
}

.applicant {
    .communication {
        .communication-inbox {
            .communication-message-item {
                &:hover {
                    background-color: $applicant-primary-color;
                }
                &-seen {
                    background-color: $lightgray;
                }
                &-not-seen > span {
                    font-weight: bold;
                    & > span:not(.message-text) {
                        font-weight: bold;
                    }
                }
            }
        }
        .communication-message-container form .communication-message-header .icon-arrow {
            @include svg($icon-arrow-right, $applicant-primary-color, 20px);
        }
    }
}

.communication {
    position: relative;
    .no-messages {
        .icon-paperplane {
        }
        a {
        }
    }

    float: left;

    .communication-inbox {
        .communication-message-item {
            p {
                &:nth-of-type(2) {
                    display: block;
                }
            }
        }
    }
    &.caseworker {
        overflow: visible;
        .applicant-seen {
            font-size: 0.8em;
        }
        width: 100%;

        .communication-inbox {
            height: calc(100vh - 150px);
            .overlay {
                .loader-container {
                    @include centerfixed(-50%, -50%);
                }
            }
        }
        .communication-head {
            > div {
                padding-right: 0;
            }

            a {
                &.close-btn {
                    z-index: 0;
                }
            }
        }
    }

    .communication-message-container {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10000;
        opacity: 0;
        background-color: white;
        @include shadow(0.3);
        visibility: hidden;
        right: 0;
        top: 0;

        &.active {
            opacity: 1;
            visibility: visible;
        }

        form {
            h2 {
                padding: 15px;
            }
            .artwork-image {
                p {
                    font-size: 0.8em;
                }

                span {
                    display: block;
                    width: 100%;
                    font-style: italic;
                }
            }

            .dialogue {
                @include dialogue;
                .correspondence-file {
                    a {
                        &:hover {
                            color: inherit;
                        }
                    }
                }
            }

            .texting {
                margin: 15px 0;
                width: 100%;
                vertical-align: top;
                padding: 0 15px;
                &.popup {
                    padding: 0;
                }

                .cke_contents {
                    height: 100px !important;
                }
                .actions {
                    .button {
                        margin-top: 15px;
                        position: relative;
                        top: 0;
                    }
                }
                > div {
                    label {
                        top: 0;
                    }
                }

                textarea {
                    width: 100%;
                    height: 100px;
                    border: 1px solid $border;
                    background-color: $alt-border;
                    margin-right: 15px;
                    padding: 15px;
                    line-height: 1.5;
                    outline: none;
                    resize: none;

                    &::-webkit-input-placeholder,
                    &::-ms-input-placeholder,
                    &:-ms-input-placeholder {
                        color: $content;
                    }
                }

                a {
                    margin-top: 0;

                    &.icon-image,
                    &.icon-attachment {
                        width: 16px;
                        height: 16px;
                        margin-bottom: 10px;
                        margin-top: 10px;
                        display: inline-block;
                    }

                    &.icon-image {
                        margin-left: 15px;
                        @include svg($icon-image, $content, 16px);
                    }

                    &.icon-attachment {
                        margin-left: 10px;
                        @include svg($icon-attachment, $content, 16px);
                    }

                    &:hover {
                        &.icon-image {
                            @include svg($icon-image, $primary-color, 16px);
                        }

                        &.icon-attachment {
                            @include svg($icon-attachment, $primary-color, 16px);
                        }
                    }
                }
            }

            .communication-message-header {
                padding: 0 10px;
                @include flex(space-between);
                align-items: center;
                a {
                    margin: 0;
                }
                .icon-arrow {
                    @include svg($icon-arrow-right, $primary-color, 20px);
                    width: 50px;
                    height: 50px;
                    border: 2px solid $white;
                    border-radius: 50px;
                    content: '';

                    &.smaller {
                        width: 40px;
                        height: 40px;
                    }
                }

                .communication-message-header-actions {
                    display: flex;
                    align-items: center;
                }

                h2 {
                    padding-left: 0;
                    padding-right: 0;
                }
                @include breakpoint-min(768px) {
                    padding-right: 30px;
                }
            }
        }
    }

    &.applicant {
        position: relative;
        background-color: $white;
        height: 100vh;
        .communication-message-container {
            width: 80%;
            &.active {
            }
        }
    }

    &.application {
        background-color: $white;
        position: relative;
        transform: scale(1) translate(100%, 0);
        top: 0;
        overflow-x: hidden;
        visibility: hidden;
        width: 100%;
        height: 100%;
        right: 0;

        .communication-inbox,
        .communication-head {
            width: 100%;
        }
        .communication-inbox {
            background-color: $white;
        }

        &.active {
            transform: scale(1) translate(0%, 0%);
            position: absolute;
            visibility: visible;
            top: 0%;
        }

        .overlay {
            //background: transparent;
            @include transition(0);
        }

        .communication-message-container {
            z-index: 40;
            transform: scale(1) translate(0, 0);
            top: 0;
            overflow-x: hidden;

            &.active {
                transform: scale(1) translate(-50%, 0%);
                position: absolute;
                top: 0%;
                left: 50%;
                width: 100%;
            }
        }

        a {
            &.close-btn {
                z-index: 30;
            }
        }
    }
}

@include breakpoint-min(768px) {
    .communication {
        .communication-head {
            > div {
                align-items: center;
            }

            padding: 25px 30px 25px 30px;
            div {
                a {
                    &.icon-refresh {
                        display: initial;
                    }

                    &.button {
                        //margin-left: 20px;
                        //margin-right: 20px;
                    }
                }
            }
        }

        .communication-inbox {
            .communication-message-item {
                padding: 10px 30px;
                &.communication-message-titles {
                    border: none;
                    display: flex;
                    padding: 10px 30px;
                    p {
                        color: black;
                    }
                    &:hover {
                        background-color: $white;
                        p {
                            color: $black;
                        }
                    }
                }
            }
            p {
                &:first-child {
                    padding-left: 21px;
                }
            }
        }
        &.application {
            .communication-inbox,
            .communication-head {
                width: 80%;
                float: right;
            }
            .communication-message-container {
                &.active {
                    transform: scale(1) translate(-50%, 0%);
                    left: 60%;
                    width: 80%;
                }
            }
        }

        &.applicant,
        &.application {
            background-color: transparent;
            .communication-inbox {
                height: calc(100vh - 160px);
            }
        }
    }
}

.admin-application-tools {
    .internal-chat {
        position: relative;
        width: 100%;
        form {
            .dialogue {
                .edit-container {
                    display: block;
                    width: 30px;
                    position: absolute;
                    top: 2px;
                    right: -35px;
                    a {
                        display: block;
                        &.edit {
                            position: relative;
                            right: 0;
                            top: 0;
                            width: 30px;
                            height: 30px;
                            margin-top: 0;
                            border: 1px $border solid;
                            border-radius: 3px;

                            &:first-child {
                                margin-bottom: 10px;
                            }
                            .icon-edit {
                                width: 30px;
                                height: 30px;
                                @include svg($icon-pen, $black, 15px);
                                @include center(-50%, -50%);
                            }

                            .icon-delete {
                                width: 30px;
                                height: 30px;
                                @include svg($icon-trash, $black, 15px);
                                @include center(-50%, -50%);
                            }

                            &:hover {
                                border-color: $primary-color-hover;
                                background-color: $primary-color-hover;

                                .icon-edit {
                                    @include svg($icon-pen, $white, 15px);
                                }

                                .icon-delete {
                                    @include svg($icon-trash, $white, 15px);
                                }
                            }
                        }
                    }
                }
                .message {
                    margin-right: 35px;
                    position: relative;

                    &.user {
                        background-color: $green-darker;
                    }
                    &.other {
                        background-color: $light-green;
                        a {
                            &.edit {
                                display: none;
                            }
                        }
                    }
                    span {
                        &.cke_top {
                            position: relative;
                            margin-bottom: 0;
                            span {
                                position: relative;
                                margin-bottom: 0;
                                a {
                                    margin-top: 0;
                                }
                            }
                        }
                    }

                    .texting {
                        padding: 0;
                        margin: 0;
                        .icon-attachment {
                            @include svg($icon-attachment, $white, 16px);
                            &:hover {
                                @include svg($icon-attachment, $primary-color-hover, 16px);
                            }
                        }

                        a {
                            display: inline-block;
                            &.agree {
                                margin-top: 8px;
                                width: 20px;
                                height: 20px;
                                @include svg($icon-check, $white, 16px);
                            }

                            &.cancel {
                                margin-top: 8px;
                                width: 20px;
                                height: 20px;
                                @include svg($icon-cross, $red, 16px);
                            }
                        }
                        .actions {
                            display: block;
                        }

                        form .upload {
                            margin-bottom: 0;
                            .icon-delete-file {
                                @include svg($icon-cross, $red, 16px);
                                &:hover {
                                    @include svg($icon-cross, $red-hover, 16px);
                                }
                            }
                        }
                    }

                    &.edit-mode {
                        width: 80%;
                    }
                }
            }
        }
    }
}
