//
// Articles
//
.articles {
    width: 100%;
    display: table;

    .container {
        width: 100%;
    }

    .article {
        margin-bottom: 60px;

        .article-image,
        .article-content {
            max-width: 100%;
        }

        .article-image {
            overflow: hidden;
            margin-bottom: 30px;
            height: 200px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .article-content {
            padding: 0 20px;

            h2 {
                padding-bottom: 15px;
            }

            a {
                margin-top: 25px;
                display: inline-block;
                font-weight: 700;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .articles {
        padding: 60px 0;

        .container {
            width: calc(100% - 60px);
        }

        .article {
            &:last-child {
                margin-bottom: 0;
            }

            .article-image {
                height: auto;
                min-height: 450px;
                margin-bottom: 50px;
            }

            .article-content {
                padding: 0 50px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .articles {
        padding: 100px 0;

        .article {
            margin-bottom: 100px;
            @include flex(space-between);

            &:nth-child(even) {
                flex-direction: row-reverse;
            }

            .article-image,
            .article-content {
                max-width: 50%;
            }

            .article-image {
                margin-bottom: 0;
            }

            .article-content {
                padding: 0 100px;
            }
        }
    }
}
