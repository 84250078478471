//
// Mobile deactivated screen
//
@media screen and (max-width: 1023px) {
    .mobile-deactivated {
        width: 100%;
        height: calc(100vh - 70px);
        position: fixed;
        top: 70px;
        left: 0;
        z-index: 3000;
        background-image: url('/assets/demo/fonda_hero_demo.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        &:before {
            content: '';
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .mobile-deactivated-content {
            width: 100%;
            max-width: 500px;
            padding: 0 30px;
            @include center(-50%, -50%);

            h2,
            p {
                color: $white;
            }

            p {
                padding: 30px 0;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .mobile-deactivated {
        display: none;
    }
}
