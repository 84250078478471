//
// Administration user search bar
//
.user-search-bar {
    width: 100%;
    padding: 15px 0;
    background-color: $white;

    .container {
        @include flex-align(center);
        @include flex(space-between);

        form {
            display: flex;
            input[type='text'] {
                border: 1px solid $border;
                padding: 6px;
                width: 200px;
                font-size: 1.2rem;
                margin-right: 15px;
            }

            .select-container {
                display: inline-block;
                margin-right: 15px;
                position: relative;

                &.active {
                    padding-right: 20px;
                }
            }

            .clear-button {
                position: absolute;
                right: 0px;
                width: 20px;
                height: 20px;
                bottom: 9px;
                border-radius: 25px;
                cursor: pointer;
                @include transition(0.5s);
                .icon-close {
                    width: 10px;
                    height: 10px;
                    @include svg($icon-cross, $red, 10px);
                    @include center(-50%, -50%);
                }

                &:hover {
                }
            }
        }

        a {
            display: table;
        }
    }
}
