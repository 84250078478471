//
// Application overview
//
#application-overview-applicant {
    padding-top: 30px;
}

#application-overview-attachments {
    padding-bottom: 50px;
}

.application-overview {
    width: 100%;
    display: table;
    padding-bottom: 10px;

    h1,
    h2 {
        font-size: 2.5em;
        padding-bottom: 30px;
        padding-top: 40px;
        clear: both;
    }

    .application-typed-info {
        width: 50%;
        min-width: 50%;
        display: inline-block;
        margin-bottom: 20px;
        float: left;

        &.first-typed {
            display: block;
            width: 100%;
        }

        p {
            &:first-child {
                font-size: 0.8em;
            }

            &:last-child {
                color: $black;
            }
        }
    }

    .application-typed-budget {
        width: 100%;
        display: table;
        padding-bottom: 30px;

        h3 {
            font-size: 0.8em;
        }

        div {
            width: 100%;
            border-bottom: 1px solid $border;
            padding: 5px 0;
            @include flex(space-between);

            p {
                &:first-child {
                    width: 70%;
                }

                &:last-child {
                    width: 30%;
                    text-align: right;

                    &:after {
                        content: ' kr.';
                    }
                }
            }

            &.subtotal {
                p {
                    color: $black;
                }
            }

            &.total {
                position: relative;
                border-color: $black;

                &:after {
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin-bottom: 2px;
                    background-color: $black;
                }

                p {
                    font-weight: 400;
                    color: $black;
                }
            }
        }
    }

    .application-typed-attachments {
        width: 100%;
        display: table;
        margin-bottom: 30px;
        @include shadow(0.3);

        div {
            padding: 15px 30px;
            border-bottom: 1px solid $border;
            @include flex(space-between);

            a {
                width: 80%;
            }

            p {
                width: 20%;
                text-align: right;
            }
        }

        .application-typed-attachments-head {
            border-bottom: none;

            p {
                font-weight: 400;
                color: $black;

                &:first-child {
                    width: 80%;
                    text-align: left;
                }

                &:last-child {
                    width: 20%;
                    text-align: right;
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    #application-overview-applicant {
        padding-top: 60px;
    }

    .application-overview {
        padding-bottom: 10px;
        width: 50%;
    }
}
