.admin-administration-general {
    .administration-header {
        @include flex(space-between);
        margin-bottom: 30px;

        .edit-fiscal-year {
            padding-left: 30px;
            background-color: $white;
            position: relative;
            cursor: pointer;

            display: flex;
            align-items: center;
            &:before {
                content: '';
                width: 20px;
                height: 20px;
                display: inline-block;
                position: absolute;
                left: 5px;
                @include svg($icon-pen, $black, 1em);
                @include transition(0.2s);
            }
            &:hover {
                &:before {
                    @include svg($icon-pen, $white, 1em);
                }
                background-color: $primary-color-hover;
            }

            &.disabled {
                cursor: auto;
                background-color: $inactive;
                &:hover {
                    border-width: 1px;
                    background-color: $inactive;
                    color: $black;
                    &:before {
                        @include svg($icon-pen, $black, 1em);
                    }
                }
            }
        }
    }

    .fiscal-year-table {
        margin-bottom: 50px;
        @include shadow(0.5);

        .table-year-header {
            padding: 10px;
            border-bottom: 1px solid $border;
            @include flex(space-between);
            .other-year {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 15px;

                span {
                    display: inline-block;

                    width: 20px;
                    height: 20px;
                }

                .next-icon {
                    margin-left: 13px;
                    @include svg($icon-arrow-right, $primary-color, 15px);
                }
                .prev-icon {
                    margin-right: 13px;
                    @include svg($icon-arrow-left, $primary-color, 15px);
                }
                a {
                }

                &:hover {
                    .next-icon {
                        @include svg($icon-arrow-right, $primary-color-hover, 15px);
                    }
                    .prev-icon {
                        @include svg($icon-arrow-left, $primary-color-hover, 15px);
                    }
                    a {
                        text-decoration: underline;
                    }
                }

                &.disabled {
                    cursor: auto;
                    visibility: hidden;
                    .next-icon {
                        @include svg($icon-arrow-right, $inactive, 15px);
                    }
                    .prev-icon {
                        @include svg($icon-arrow-left, $inactive, 15px);
                    }
                    a {
                        cursor: auto;
                        color: $inactive;
                    }

                    &:hover {
                        .next-icon {
                            @include svg($icon-arrow-right, $inactive, 15px);
                        }
                        .prev-icon {
                            @include svg($icon-arrow-left, $inactive, 15px);
                        }
                        a {
                            text-decoration: none;
                            color: $inactive;
                        }
                    }
                }
            }
            .current-year {
                text-align: center;
                a {
                    font-size: 0.9em;
                    display: block;
                }
            }
        }

        table {
            margin-top: 10px;
            @include table;
            @include shadow(0);
            th {
                color: $black;
                font-weight: normal;
            }

            tr {
                &.poul {
                    background-color: $alt-border;
                    border-bottom: 1px solid $border;
                }
                td,
                th {
                    &:last-child {
                        text-align: center;
                        width: 160px;
                    }

                    &:nth-child(2) {
                        div {
                            margin-right: 50%;
                            text-align: right;
                        }
                    }
                    .expand-icon {
                        background-size: 20px;
                    }
                }
            }
        }
    }

    .edit-general-container {
        @include shadow(0.3);
        width: 100%;
        display: flex;
        .edit-block {
            width: 100%;
            padding: 15px;
            border-right: 1px solid $border;
            &:last-child {
                border: 0;
            }

            h3 {
                display: block;
            }

            .form-item {
                float: none;
                height: 80px;

                .fonda-datepicker-icon {
                    bottom: 32px;
                }

                .select-box {
                    padding-bottom: 8px;
                }
            }

            .button {
                &:disabled {
                    background-color: $inactive;
                    &:hover {
                        background-color: $inactive;
                        color: $white;
                    }
                }
                &.disabled {
                    background-color: $inactive;
                    &:hover {
                        background-color: $inactive;
                        color: $white;
                    }
                }
            }
        }

        margin-bottom: 400px; //for datepicker
    }
}
