//
// Search
//
//
// Search
//
.basic-search-area {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
    visibility: hidden;
    opacity: 0;
    @include shadow(0.3);
    @include scale(0);
    @include transition(0.5s);

    &.active {
        opacity: 1;
        visibility: visible;
        display: table;
    }

    .container {
        position: relative;
        z-index: 30;
        margin-bottom: 30px;
    }

    #menu-search-close {
        width: 50px;
        height: 50px;
        border: 1px solid $border;
        border-radius: 100%;
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 30px;

        div {
            width: 20px;
            height: 20px;
            @include svg($icon-cross, $black, 16px);
            @include center(-50%, -50%);
        }

        &:hover {
            background-color: $primary-color-hover;
            border: none;

            div {
                @include svg($icon-cross, $white, 16px);
            }
        }
    }

    form {
        h2 {
            text-align: center;
            padding: 30px 0 10px;
        }

        .form-item {
            input {
                font-size: 2em;
                text-align: center;
            }

            input:placeholder-shown ~ label {
                font-size: 2em;
                width: 100%;
                text-align: center;
                font-style: normal;
                color: $black;
            }

            input:focus ~ label,
            input:not(:placeholder-shown) ~ label {
                font-size: 12px;
                width: 100%;
                text-align: center;
            }
        }
    }

    .blur-bg {
        background-color: rgba(255, 255, 255, 0.9);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        filter: blur(0.5px);
    }
}

@media screen and (min-width: 768px) {
    .basic-search-area {
        width: calc(100% - 60px);
        height: calc(100vh - 130px);
        transform-origin: center;
        @include scale(0.1);

        &.active {
            transform-origin: center;
            @include scalefixed(1, -50%, 0);

            top: 10px;
        }

        form {
        }
    }
}
.search-area {
    color: $black;
    select {
        padding: 8px;
        background: transparent;
        border: 1px groove;
        &.border-less {
            border: none;
        }

        option {
            padding: 5px 1px;
        }
    }

    a,
    button {
        &.button {
            padding: 8px 15px;

            &.smaller {
                padding: 6px 10px;
            }
        }
    }
    .search-actions-container {
        @include flex(space-between);
        align-items: center;
        padding: 11px 50px;
        background-color: $primary-color;
        select {
            font-size: initial;
            width: 150px;
        }

        a {
            margin-left: 20px;
            color: $primary-color;
        }
        .search-list {
        }
        .my-cases-button {
        }

        input[type='text']:not(.default) {
            border: 1px groove;
            padding: 6px;
            width: 200px;
            font-size: 1.1em;
        }

        .select-component {
            margin-right: 10px;
            color: $white;
            border-color: $border;
            .option-component {
                color: $black;
            }
            &:after {
                @include svg($icon-arrow-down, $white, 15px);
            }
            &:hover {
                background-color: $primary-color-hover;
            }
        }

        .manage-search-button {
            color: $white;
            cursor: pointer;
            background-color: transparent;
            @include transition(0.5s);
            &:hover {
                background-color: $primary-color-hover;
                border-color: $white;
            }
            margin-left: 10px;
            &:first-child {
                margin-left: 0px;
            }
            &:disabled {
                background-color: $inactive;
                cursor: auto;
                &:hover {
                    background-color: $inactive;
                }
            }
        }

        .manage-search-popup {
            width: 700px;
            z-index: 9999;
            background-color: rgba(255, 255, 255, 1);
            visibility: hidden;
            opacity: 0;
            @include shadow(0.3);
            @include scale(0);
            @include transition(0.5s);
            @include centerfixed(-50%, -50%);

            top: calc(50% + 35px);
            overflow: auto;

            padding: 20px;

            .search-popup-header {
                @include flex(space-between);
                align-items: center;
                margin-bottom: 20px;
            }

            h3 {
                margin-bottom: 25px;
            }

            &.active {
                opacity: 1;
                visibility: visible;
                display: block;
            }

            .close-btn {
                display: inline-block;
                width: 40px;
                height: 40px;
            }

            a {
                margin-left: 0;
                color: $white;
                &.button {
                    margin-top: 10px;
                }
                &.error {
                    display: block;
                    color: red;
                    font-weight: 700;
                }
            }

            form {
                .checkbox {
                    input[type='checkbox'] + label:before {
                        margin-right: 10px;
                    }
                }
            }

            table {
                @include table;

                tr {
                    td,
                    th {
                        padding: 10px 2px;
                        padding-left: 10px;

                        &:nth-child(3) {
                            text-align: center;
                        }
                    }
                }
            }
        }

        .saved-searches-container {
            @include flex(flex-start);
            .save-search {
                margin-left: 10px;
            }
        }
    }

    .filters-container {
        .static {
            @include flex(flex-start);
            li {
                margin-right: 10px;
            }
            align-items: center;
        }

        padding: 20px 50px 10px 40px;
        > ul {
            padding-left: 10px;
            > li {
                display: inline-block;

                .mass-search {
                    border: 1px groove;
                    padding: 7px;
                    width: 200px;
                    font-size: 1.1em;
                    height: 38px;
                }
                height: auto;
                transition: visibility 0s, opacity 0.5s ease-out, height 0.5s;

                .filters-expand {
                    padding: 14px 32px 11px 8px;
                    position: relative;
                    @include transition(0.5s);
                    cursor: pointer;
                    &:after {
                        content: '';
                        width: 20px;
                        position: absolute;
                        right: 8px;
                        height: 20px;
                        position: absolute;
                        display: inline-block;
                        @include svg($icon-arrow-down, $primary-color, 15px);
                        @include transition(0.5s);
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    &.active {
                        &:after {
                            @include rotate(180deg);
                        }
                    }
                }

                &.disabled {
                    display: none;
                }
            }
        }
    }

    .search-list-container {
        .loader-container {
            width: 100%;
            text-align: center;
        }
        .loader {
            text-align: center;
            @include loader();
            display: none;
        }

        &.loading {
            display: block;
            height: 400px;

            table {
                visibility: hidden;
            }

            .loader {
                display: inline-block;
            }
        }

        padding: 50px;
        background-color: #e7e9f2;
        position: relative;
        .options-container {
            margin-bottom: 40px;
            @include flex(space-between);

            &.block {
                position: absolute;
                display: block;
                top: 40px;
                right: 50px;
            }
            .select-component {
                cursor: pointer;
                &:hover {
                    background-color: $primary-color-hover;
                    color: white;
                }
                &:after {
                    top: 30%;
                }
                .option-component-container {
                    &:hover {
                        border: 1px black solid;
                    }
                }
                .option-component {
                    color: black;
                    &:hover {
                        color: white;
                    }
                }
                padding: 8px 15px;

                line-height: 1.5;
                padding-right: 30px;
                border: 1px solid #cccccc;
                border-radius: 3px;
                .option-component-container {
                    div {
                        display: block;
                    }
                }
            }
            ul {
                li {
                    cursor: pointer;
                    display: inline-block;
                    &.active {
                        border-bottom: 1px $primary-color solid;
                    }
                }
            }

            div {
                display: inline-block;
            }

            a {
                &.options {
                    @include svg($icon-settings, $black, 25px);
                    width: 42px;
                    height: 42px;
                    vertical-align: bottom;

                    &:hover {
                        @include svg($icon-settings, $white, 25px);
                    }
                }
            }
        }
        table {
            width: 100%;
            border-collapse: collapse;

            th {
                font-size: 1em;
                text-align: left;
                padding: 10px;
                min-width: 100px;

                &:first-child {
                    min-width: 20px;
                }

                .order {
                    position: relative;
                    top: 2px;
                    @include svg($icon-arrow-down, $primary-color, 15px);
                    width: 15px;
                    display: inline-block;
                    height: 15px;
                    margin-left: 3px;
                    @include transition(0s);
                    &.desc {
                        @include rotate(180deg);
                    }
                }
                &.non-order-able {
                    cursor: auto;
                }
            }

            td {
                padding: 15px 6px;
                min-width: 110px;
                max-width: 110px;
                word-wrap: break-word;

                &:first-child {
                    min-width: 20px;
                }
            }

            td,
            th {
                text-align: center;
                &:nth-child(2) {
                    text-align: left;
                }
            }

            tr {
                cursor: pointer;
                border-bottom: 1px $border solid;
                background-color: $white;
                &:first-child,
                &.money-result {
                    background-color: transparent;
                    border-bottom: none;
                    span {
                        display: block;
                        color: $black;
                        font-weight: 400;
                        border-bottom: 1px solid $black;
                    }
                }
                &:hover {
                    background-color: $primary-color-hover;
                    color: $white;
                    &:first-child,
                    &.money-result {
                        background-color: transparent;
                        color: $black;
                    }
                }
            }
        }
    }
}

.icon-checkbox {
    background-color: white;
    width: 30px;
    height: 30px;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    position: relative;
    &.checked {
        &:after {
            @include svg($icon-check, $black, 20px);
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            top: 4px;
            left: 4px;
        }
    }
}

.icon-locked-by-other {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    background-color: $primary-color;
    border-radius: 50%;
    display: inline-block;
}

@media screen and (min-width: 768px) {
}
