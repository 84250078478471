.granted-amount {
    margin: 30px 0;
    table {
        @include table;
        tr {
            th {
                padding: 10px;
            }
        }
    }
    &__header {
        margin-top: 0;
        @include flex(space-between);
        align-items: center;
        padding: 0 0 20px 0;
        button {
            &.smaller {
                height: 40px;
            }
            &.add {
                line-height: 0.5em;
                width: 40px;
                font-size: 2em;
                margin: 0;
            }
        }
    }
}
