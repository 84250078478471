table.fonda-table {
    width: 100%;
    border: 1px solid $border;
    border-collapse: collapse;

    > tr,
    > tbody > tr,
    > thead > tr {
        > td,
        > th {
            padding: 10px 20px;
            text-align: left;
        }

        > th.to-right,
        td.to-right {
            text-align: right;
        }
    }
}

table.fonda-search-results-table {
    border: none;
    width: 100%;
    border-collapse: collapse;

    th {
        font-weight: 700;
        color: $black;
        text-align: center;
        padding: 10px 20px;
    }

    td {
        text-align: center;
        padding: 10px 20px;
        border: none;
    }

    tbody {
        tr {
            cursor: pointer;
            background: $white;

            border-bottom: 1px solid $lightgray;

            &:hover {
                background: $fonda-blue;
                color: $white;
            }
        }
    }
}
