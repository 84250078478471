//
// Typography
//

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    color: $black;
    line-height: 1.2;

    p,
    a,
    span {
        font-size: 1em;
    }
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.8rem;

    &.budget-attachments {
        margin-bottom: 30px;
    }
}

.flex {
    display: flex;

    &.items-center {
        align-items: center;
    }

    &.between {
        justify-content: space-between;
    }

    &.justify-end {
        justify-content: flex-end;
    }
}

h3 {
    font-size: 1.3rem;
}

h4,
h5,
h6 {
    font-size: 1rem;
}

p,
a,
span {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    vertical-align: middle;
}

a[href] {
    &.unlink {
        font-weight: 300;
    }
}

i {
    color: $content;
}

p {
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    &.ckeditor-generated {
        padding-left: 20px;
        list-style: initial;
    }
}

p {
    &.ckeditor-generated {
        margin-bottom: 10px;

        &:first-child {
            margin-bottom: 0;
        }
    }
}

a,
button {
    font-family: inherit;
    color: $content;
    text-decoration: none;
    @include transition(0.5s);

    &.button {
        cursor: pointer;
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5;
        display: inline-block;
        color: $black;
        padding: 12px 15px;
        border: 1px solid $border;
        border-radius: 3px;
        @include transition(0.2s);

        &.btn-center {
            margin: 0 auto;
            display: table;
        }

        &.button-hero {
            color: $white;
        }

        &.smaller {
            padding: 6px 10px;
        }
    }

    &.white {
        background-color: $white;
    }

    &.white-blue {
        background-color: $white;
    }

    &.color-blue {
        background-color: $primary-color;
        color: $white;
        border: none;
    }

    &.color-success-black {
        border: 1px solid $black;
        background-color: $green-hover;
        background-color: var(--success-background-color-hover);
        color: $black;
        color: var(--success-color);
    }

    &.icon-btn-run {
        div,
        span {
            vertical-align: top;
        }

        div {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: transparent;
            border: 1px solid $light-green;
            position: relative;
            display: inline-block;
            @include transition(0.5s);

            div {
                width: 30px;
                height: 30px;
                border: none;
                @include svg($icon-run, $light-green, 25px);
                @include center(-45%, -50%);
            }
        }

        span {
            height: 50px;
            line-height: 50px;
            padding-left: 15px;
        }

        &.smaller {
            > div {
                width: 40px;
                height: 40px;
            }
        }
    }

    &.download-icon {
        @include transition(0.25s);
        @include svg($icon-download, $black, 25px);
        width: 50px;
        height: 50px;
        cursor: pointer;
        background-color: $white;
        border: 1px solid $lightgray;

        &.rounded {
            border-radius: 50%;
        }

        &.smaller {
            width: 30px;
            height: 30px;
            @include svg($icon-download, $black, 15px);
        }
    }

    &.download-zip-icon {
        @include transition(0.25s);
        @include svg($icon-zip-download, $black, 25px);
        width: 50px;
        height: 50px;
        display: inline-block;
        cursor: pointer;
        background-color: $white;
        border: 1px solid $lightgray;

        &.rounded {
            border-radius: 50%;
        }

        &.smaller {
            width: 30px;
            height: 30px;
            @include svg($icon-zip-download, $black, 15px);
        }
    }

    &.download-pdf-icon {
        @include transition(0.25s);
        @include svg($icon-pdf-download, $black, 25px);
        width: 50px;
        height: 50px;
        display: inline-block;
        cursor: pointer;
        background-color: $white;
        border: 1px solid $lightgray;

        &.rounded {
            border-radius: 50%;
        }

        &.smaller {
            width: 30px;
            height: 30px;
            @include svg($icon-pdf-download, $black, 15px);
        }
    }

    &.disabled {
        background-color: $inactive;
        color: $white;
    }

    &:disabled {
        cursor: auto;
        background-color: $inactive;
        color: $white;
    }

    &.color-green {
        background-color: $green;
        background-color: var(--success-background-color);
        color: $white;
        color: var(--success-color);
        border: none;
    }

    &.dropdown {
        &:after {
            content: '';
            width: 10px;
            height: 14px;
            display: inline-block;
            margin-left: 10px;
            @include svg($icon-arrow-down, $white, 10px);
        }
    }

    &.icon-btn-left {
        div,
        span {
            vertical-align: top;
        }

        div {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: transparent;
            border: 1px solid $border;
            position: relative;
            display: inline-block;
            @include transition(0.5s);

            div {
                width: 16px;
                height: 16px;
                border: none;
                @include svg($icon-arrow-left, $black, 1em);
                @include center(-55%, -50%);
            }
        }

        span {
            height: 50px;
            line-height: 50px;
            padding-left: 15px;
        }

        &.inverted {
            transform: rotate(180deg);
        }
    }

    &.icon-btn-right {
        div,
        span {
            vertical-align: top;
        }

        div {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background-color: transparent;
            border: 1px solid $border;
            position: relative;
            display: inline-block;
            @include transition(0.5s);

            div {
                width: 16px;
                height: 16px;
                border: none;
                @include svg($icon-arrow-right, $black, 1em);
                @include center(-55%, -50%);
            }
        }

        span {
            height: 50px;
            line-height: 50px;
            padding-left: 15px;
        }

        &.inverted {
            transform: rotate(180deg);
        }

        &.down {
            transform: rotate(-90deg);
        }

        &.smaller {
            div,
            span {
                width: 40px;
                height: 40px;

                div {
                    @include center(-50%, -50%);
                }
            }
        }

        &.smallest {
            div,
            span {
                width: 30px;
                height: 30px;

                div {
                    @include center(-50%, -50%);
                }
            }
        }
    }

    &.icon-btn-left-small {
        margin: 0 10px 0 0;
        width: 16px;
        display: inline-block;
        height: 16px;
        border: none;
        @include svg($icon-arrow-left, $primary-color, 1em);
    }

    &.icon-btn-down-small {
        margin: 0 10px 0 0;
        width: 16px;
        display: inline-block;
        height: 16px;
        border: none;
        @include svg($icon-arrow-down, $primary-color, 1em);
    }

    &.icon-btn-right-small {
        margin: 0 10px 0 0;
        width: 16px;
        display: inline-block;
        height: 16px;
        border: none;
        @include svg($icon-arrow-right, $primary-color, 1em);
    }

    &.red-close-btn {
        width: 25px;
        position: relative;
        display: block;
        height: 25px;

        .icon-close {
            width: 25px;
            height: 25px;
            @include svg($icon-cross, $red, 14px);
        }
    }

    &.close-btn {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $border;
        position: relative;
        display: inline-block;

        .icon-close {
            width: 16px;
            height: 16px;
            @include svg($icon-cross, $black, 16px);
            @include center(-50%, -50%);
            @include transition(0.5s);
        }

        &.smaller {
            width: 40px;
            height: 40px;

            .icon-close {
                width: 14px;
                height: 14px;
                @include svg($icon-cross, $black, 14px);
            }
        }

        &.smallest {
            width: 25px;
            height: 25px;

            .icon-close {
                width: 14px;
                height: 14px;
                @include svg($icon-cross, $black, 14px);
            }
        }

        &.red {
            display: inline-block;
        }
    }

    &.approve-btn {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $border;
        position: relative;
        display: block;

        .icon-approve {
            width: 16px;
            height: 16px;
            @include svg($icon-approve, $black, 16px);
            @include center(-50%, -50%);
            @include transition(0.5s);
        }

        &.smaller {
            width: 40px;
            height: 40px;

            .icon-close {
                width: 14px;
                height: 14px;
                @include svg($icon-approve, $black, 14px);
            }
        }

        &.smallest {
            width: 25px;
            height: 25px;

            .icon-close {
                width: 14px;
                height: 14px;
                @include svg($icon-approve, $black, 14px);
            }
        }
    }

    &.trash-btn {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $border;
        position: relative;
        display: block;

        .icon-trash {
            width: 20px;
            height: 20px;
            @include svg($icon-trash, $black, 16px);
            @include center(-50%, -50%);
        }

        &.smaller {
            width: 40px;
            height: 40px;

            .icon-trash {
                width: 14px;
                height: 14px;
                @include svg($icon-trash, $black, 14px);
            }
        }

        &.smallest {
            width: 30px;
            height: 30px;

            .icon-trash {
                width: 14px;
                height: 14px;
                @include svg($icon-trash, $black, 14px);
            }
        }

        &.rounded {
            border-radius: 50%;
        }
    }

    &.pencil-button {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $border;
        position: relative;
        display: block;

        .icon-pencil {
            width: 16px;
            height: 16px;
            @include svg($icon-pen, $black, 16px);
            @include center(-50%, -50%);
            @include transition(0.5s);
        }

        &.smaller {
            width: 38px;
            height: 38px;

            .icon-pencil {
                width: 16px;
                height: 16px;
                @include svg($icon-pen, $black, 16px);
            }
        }

        &.rounded {
            border-radius: 50%;
        }

        &.smallest {
            width: 30px;
            height: 30px;
        }
    }

    &.bullet-point-icon {
        width: 50px;
        height: 50px;
        border-radius: 3px;
        border: 1px solid $border;
        position: relative;
        display: block;

        .icon-bullet {
            width: 40px;
            height: 40px;
            @include svg($icon-bullet-list, $black, 18px);
            @include center(-50%, -50%);
            @include transition(0.5s);
        }

        &.smaller {
            width: 40px;
            height: 40px;

            .icon-pencil {
                width: 35px;
                height: 35px;
                @include svg($icon-bullet-list, $black, 18px);
            }
        }

        &.rounded {
            border-radius: 50%;
        }

        &.smallest {
            width: 30px;
            height: 30px;
        }
    }

    &.communication-button {
        width: 50px;
        height: 50px;
        border: 1px solid $border;
        display: inline-block;
        position: relative;
        border-radius: 100%;
        float: right;

        div {
            width: 16px;
            height: 16px;
            @include svg($icon-mail, $black, 16px);
            @include center(-50%, -50%);
        }
    }

    &.communication-print {
        width: 50px;
        height: 50px;
        border: 1px solid $border;
        display: inline-block;
        position: relative;
        border-radius: 100%;
        margin-right: 10px;
        float: right;

        div {
            width: 16px;
            height: 16px;
            @include svg($icon-printer, $black, 16px);
            @include center(-50%, -50%);
        }
    }

    &.btn-delete {
        color: $red;
        border: none;
        float: right;
    }

    &.icon-refresh {
        width: 50px;
        height: 50px;
        background-color: $white;
        position: relative;
        border-radius: 3px;
        border: 1px solid $border;

        .refresh {
            display: block;
            @include center(-50%, -50%);
            @include svg($icon-refresh, $black, 20px);
            width: 30px;
            height: 30px;
        }

        &.smaller {
            width: 40px;
            height: 40px;
        }
    }

    &:hover {
        color: $primary-color-hover;

        &.readonly {
            color: $content;
            cursor: pointer;
        }

        &.button {
            color: $white;
            background-color: $primary-color-hover;
            border-color: $primary-color-hover;

            &:after {
                content: none;
            }
        }

        &.white-blue {
            border-color: $primary-color-hover;
            background-color: $primary-color-hover;
            color: $white;
        }

        &.disabled {
            border-color: $inactive;
            background-color: $inactive;
            color: $white;
        }

        &.download-icon {
            border-color: $primary-color-hover;
            background-color: $primary-color-hover;
            @include svg($icon-download, $white, 25px);

            &.smaller {
                @include svg($icon-download, $white, 15px);
            }
        }

        &.download-zip-icon {
            border-color: $primary-color-hover;
            background-color: $primary-color-hover;
            @include svg($icon-zip-download, $white, 25px);

            &.smaller {
                @include svg($icon-zip-download, $white, 15px);
            }
        }

        &.download-pdf-icon {
            border-color: $primary-color-hover;
            background-color: $primary-color-hover;
            @include svg($icon-pdf-download, $white, 25px);

            &.smaller {
                @include svg($icon-pdf-download, $white, 15px);
            }
        }

        &:disabled {
            border-color: $inactive;
            background-color: $inactive;
            color: $white;
        }

        &.icon-refresh {
            background-color: $primary-color-hover;

            .refresh {
                @include svg($icon-refresh, $white, 20px);
            }
        }

        &.pencil-button {
            background-color: $primary-color-hover;

            .icon-pencil {
                @include svg($icon-pen, $white, 16px);
            }
        }

        &.bullet-point-icon {
            background-color: $primary-color-hover;

            .icon-bullet {
                @include svg($icon-bullet-list, $white, 20px);
            }
        }

        &.color-blue {
            background-color: $primary-color-hover;
            color: $white;
        }

        &.color-success-black {
            background-color: $green;
            background-color: var(--success-background-color);
            color: $black;
            color: var(--success-color);
        }

        &.color-green {
            background-color: $green-hover;
            background-color: var(--success-background-color-hover);
            color: $white;
            color: var(--success-color);
        }

        &.icon-btn-left {
            div {
                background-color: $primary-color-hover;
                border: none;

                div {
                    background-color: transparent;
                    @include svg($icon-arrow-left, $white, 1em);
                }
            }

            span {
                color: $primary-color-hover;
            }
        }

        &.icon-btn-run {
            div {
                background-color: $green-hover;
                background-color: var(--success-background-color);
                border-color: transparent;

                div {
                    background-color: transparent;
                    @include svg($icon-run, $white, 25px);
                }
            }

            span {
                color: $primary-color-hover;
            }
        }

        &.icon-btn-right {
            div {
                background-color: $primary-color-hover;
                border: none;

                div {
                    background-color: transparent;
                    @include svg($icon-arrow-right, $white, 1em);
                }
            }

            span {
                color: $primary-color-hover;
            }
        }

        &.icon-btn-left-small {
            @include svg($icon-arrow-left, $primary-color-hover, 1em);
        }

        &.icon-btn-down-small {
            @include svg($icon-arrow-down, $primary-color-hover, 1em);
        }

        &.close-btn {
            background-color: $primary-color-hover;
            border: none;

            &.red {
                background-color: $red-hover;
            }

            .icon-close {
                @include svg($icon-cross, $white, 16px);
            }

            &.smallest {
                .icon-close {
                    @include svg($icon-cross, $white, 14px);
                }
            }
        }

        &.red-close-btn {
            .icon-close {
                @include svg($icon-cross, $red-hover, 14px);
            }
        }

        &.approve-btn {
            background-color: $green-hover;
            background-color: var(--success-background-color);
            border: none;

            &.red {
                background-color: $red-hover;
            }

            .icon-approve {
                @include svg($icon-approve, $white, 16px);
            }

            &.smallest {
                .icon-close {
                    @include svg($icon-approve, $white, 16px);
                }
            }
        }

        &.trash-btn {
            background-color: $primary-color-hover;
            border: none;

            .icon-trash {
                @include svg($icon-trash, $white, 16px);
            }

            &.smaller {
                .icon-trash {
                    @include svg($icon-trash, $white, 14px);
                }
            }

            &.smallest {
                .icon-trash {
                    @include svg($icon-trash, $white, 14px);
                }
            }
        }

        &.communication-button {
            background-color: $primary-color-hover;
            border: none;

            div {
                @include svg($icon-mail, $white, 16px);
            }
        }

        &.communication-print {
            background-color: $primary-color-hover;
            border: none;

            div {
                @include svg($icon-printer, $white, 16px);
            }
        }

        &.btn-delete {
            color: $red-hover;
            background-color: transparent;
        }

        &.dropdown {
            &:after {
                content: '';
                width: 10px;
                height: 14px;
                display: inline-block;
                margin-left: 10px;
                @include svg($icon-arrow-down, $white, 10px);
            }
        }

        &:disabled {
            background-color: $inactive;
        }
    }
}

a {
    color: var(--link-color);
    &:hover,
    &:focus {
        color: var(--link-color-hover);
    }
}

strong {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

@media screen and (min-width: 768px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
    }

    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2.2rem;
    }

    h3 {
        font-size: 1.5rem;
    }
}
