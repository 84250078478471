.select-box {
    cursor: pointer;
    position: relative;
    border-bottom: 2px solid $primary-color;
    border-radius: 0;
    padding: 30px 3px 12px;
    appearance: none;
    height: 60px;
    color: #333333;
    -webkit-appearance: none;

    .dropdown-container {
        z-index: 10;
        overflow: auto;
        max-height: 300px;
        position: absolute;
        top: 60px;
        width: 100%;
        left: 0;
        @include shadow(0.5);
        .dropdown-element {
            cursor: pointer;
            color: $black;
            padding: 3px;
            border-bottom: 1px solid $lightgray;
            background-color: $white;
            &:hover {
                color: $white;
                background-color: $primary-color;
            }

            .orange {
                color: orange;
            }
        }
    }

    &:after {
        position: absolute;
        right: 0;
        width: 15px;
        height: 15px;
        top: 52%;
        content: '';
        @include svg($icon-arrow-down, $primary-color, 14px);
    }

    &.color-mode {
        .dropdown-container .dropdown-element {
            background-color: $white;
            abbr {
                &.orange {
                    color: #c87b44;
                }
                &.green {
                    color: $green-hover;
                    color: var(--success-background-color);
                }
            }
            &:hover {
                abbr {
                    text-decoration: underline;
                }
            }
        }
    }

    &.green {
        border-bottom: 2px solid $green-hover;
        border-bottom: 2px solid var(--success-background-color);
        color: $green-hover;
        color: var(--success-color);
    }
    &.orange {
        border-bottom: 2px solid #c87b44;
        color: #c87b44;
    }
}

.select-box-label {
    font-style: normal;
    top: 8px !important;
    font-size: 12px !important;

    &.red {
        color: $red;
        cursor: pointer;
        z-index: 10;
        &:hover {
            text-decoration: underline;
        }
    }
}

.required {
    > .select-box-label {
        &:after {
            content: ' *';
            color: $red;
        }
    }
}

.applicant {
    .select-box {
        border-bottom: 2px solid $applicant-primary-color-hover;
        .dropdown-container {
            .dropdown-element {
                &:hover {
                    color: $white;
                    background-color: $applicant-primary-color;
                }
            }
        }
        &:after {
            @include svg($icon-arrow-down, $applicant-primary-color-hover, 14px);
        }
    }
}
