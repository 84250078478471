.bottom-info-bar {
    width: 56%;
    height: 10%;
    bottom: 0;
    position: fixed;

    background-color: #ffe082;
    border-left: 8px solid #ffca28;

    margin-left: 22%;

    padding-top: 8px;
    padding-bottom: 8px;

    display: flex;

    .icon-box {
        height: 100%;
        width: 10%;
        @include svg($icon-lock, #c79a00, 15px);
    }
    .text-box {
        height: 100%;
        width: 80%;
        color: hsla(0, 0%, 29%, 0.86);
    }

    .title {
        font-weight: bold;
        color: hsla(0, 0%, 13%, 0.86);
    }
}
