//
// Administration application bar
//
.admin-application-bar {
    width: 100%;
    margin-top: 70px;
    background-color: $primary-color;
    padding: 15px 0;
    z-index: 2;
    position: relative;
    &.fixed {
        position: fixed;
    }
    .application-info {
        display: inline-flex;
        li {
            color: $white;
            padding: 13px;
            span {
                color: $white;
            }
        }
    }
    .application-options {
        float: right;
        display: table;
        .select-component {
            cursor: pointer;
            padding: 14px 15px;
            padding-right: 30px;
            border: 1px solid #cccccc;
            border-radius: 3px;
            color: $white;
            font-weight: 300;
            .option-component {
                color: #333333;
            }
            .option-component-container {
                @include shadow(0.5);
            }
            &:hover {
                border-color: $primary-color-hover;
                background-color: $primary-color-hover;
                color: $white;
            }
            &:after {
                content: '';
                width: 10px;
                height: 14px;
                display: inline-block;
                margin-left: 10px;
                @include svg($icon-arrow-down, $white, 10px);
                top: 18px;
            }
        }
        li {
            display: inline-block;
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            a {
                span {
                    font-weight: 300;
                    color: $content;
                }

                &.color-blue {
                    @include shadow(0.3);
                }
            }
        }
    }
    &.applicant-nav {
        padding: 10px;

        display: inline-flex;
        li {
            color: $white;
            padding: 13px;
            display: inline-block;
            span {
                color: $white;
                cursor: pointer;
                opacity: 0.6;
                &.hidden {
                    display: none;
                }

                &.active {
                    opacity: 1;
                    font-weight: 700;
                }

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
