//
// Administration blocks
//
.administration-block {
    margin-top: 20px;
    margin-bottom: 80px;
    background-color: $white;
    @include flex(space-between);
    @include shadow(0.3);

    .block {
        width: 50%;
        max-width: 50%;

        &:nth-child(1) {
            padding: 0 30px 30px;
            border-right: 1px solid $border;
        }

        &.right {
            h3 {
                padding: 30px 30px 20px;
            }
        }

        h3 {
            padding: 30px 0 20px;
        }

        form {
            a {
                &.button {
                    &:last-child {
                        margin-left: 5px;
                    }
                }
            }
        }

        ul {
            width: 100%;

            &.listed {
                button {
                    margin: 0;
                }
                max-height: 320px;
                overflow-y: scroll;

                li {
                    width: inherit;
                    height: 70px;
                    padding: 0 30px;
                    border-bottom: 1px solid $border;
                    @include flex(space-between);
                    @include flex-align(center);

                    &:last-child {
                        border-bottom: 0;
                    }

                    p {
                        width: 30%;
                        max-width: 60%;
                        font-size: 1.5em;
                        color: $black;

                        span {
                            font-size: 13px;
                            top: -3px;
                            position: relative;
                            line-height: 0;
                            font-weight: 700;
                            color: $placeholder;
                            text-transform: uppercase;
                        }
                    }

                    &.titles {
                        height: 45px;
                        p {
                            font-size: 1em;
                        }
                    }

                    a {
                        max-width: 100%;

                        &.edit {
                            text-align: right;
                        }

                        &.close-btn {
                            opacity: 0;
                            visibility: hidden;
                            &.active {
                                opacity: 1;
                                visibility: visible;
                            }
                            &:hover {
                                background-color: $red-hover;
                            }
                        }
                    }

                    .actions {
                        width: 30%;
                        @include flex(flex-end);
                        align-items: center;

                        a {
                            display: inline-block;
                            margin-right: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &.active {
                        background-color: $alt-border;
                    }
                }
            }
        }

        #categories {
            padding: 0 30px 30px;

            a {
                float: left;
                margin-right: 10px;
                margin-bottom: 10px;
                @include flex(center);
                @include flex-align(center);

                .icon-close {
                    margin-left: 15px;
                    width: 13px;
                    height: 13px;
                    @include svg($icon-cross, $black, 13px);
                }

                &:hover {
                    .icon-close {
                        @include svg($icon-cross, $white, 13px);
                    }
                }
            }
        }
    }

    a {
        &.button {
            margin-top: 15px;
        }
    }
}

.fiscal-year {
    h2 {
        margin-bottom: 40px;
    }
    width: 100%;
    max-width: 800px;
    margin: auto;
}
