//
// Footer
//
footer {
    width: 100%;
    z-index: 100;
    position: relative;

    .footer {
        padding: 10px 0 20px 0;
        background-color: $alt-white;
        display: table;
        width: 100%;

        .widget {
            width: 100%;
            margin-bottom: 50px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .logo {
                width: 195px;
                height: 50px;
                display: block;
                margin-bottom: 15px;
                @include logo(cover);
            }

            h4 {
                margin-bottom: 15px;
                font-weight: 700;
            }

            p,
            a,
            span {
                //font-size: 14px;
            }

            p {
                margin: 0;
                line-height: 2;
            }

            span {
                line-height: 2;
                display: block;
            }
        }
    }

    .socket {
        width: 100%;
        background-color: $lightgray;
        display: table;

        #links {
            display: table;
            margin: 0 auto;

            a {
                font-size: 0.8em;
                margin: 15px 20px 15px 0;
                display: inline-block;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        #copyright {
            display: table;
            margin: 0 auto;

            span {
                margin: 15px 0;
                display: inline-block;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    footer {
        .footer {
            .container {
                @include flex(center);
            }
            .widget {
                width: 50%;
                margin-left: 30px;
                vertical-align: top;

                &:first-child {
                    margin-left: 0;
                    width: 100%;
                }

                h4 {
                    margin-top: 15px;
                }
            }
        }

        .socket {
            > .container {
                @include flex(space-between);
                align-items: center;
            }
            #links a,
            #copyright span {
                //font-size: 14px;
            }

            #links {
                margin: 0;
            }

            #copyright {
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    footer {
        .footer {
            .widget {
                width: 50%;
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }

                .logo {
                    width: 130px;
                    height: 70px;
                    background-size: contain;
                }

                h4 {
                    margin-top: 15px;
                }

                p {
                    font-size: 1em;
                }
            }
        }

        .socket {
            #links a,
            #copyright span {
                font-size: 1em;
            }
        }
    }
}
