.warning-box {
    .box-container {
        padding: 18px;
        @include flex(flex-start);
        align-items: center;
    }
    background-color: $tangerine;
    .icon {
        cursor: auto;
        width: 40px;
        height: 40px;
        @include svg($icon-warning, $white, 35px);
        margin-right: 20px;
    }

    .description {
        h3 {
            color: $white;
        }
        p {
            color: $white;
            margin: 0;
        }
    }
}
