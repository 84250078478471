.translation-manager-container {
    table {
        @include table;
        margin-bottom: 30px;

        tr {
            td,
            th {
                padding: 10px 0 10px 10px;
                &:first-child {
                    width: 400px;
                    max-width: 400px;
                }
                &:last-child {
                    width: 90px;
                    padding: 10px;
                    text-align: center;
                    &.edit {
                        width: 90px;
                    }
                }

                &.edit-text {
                    @include breakpoint-min(1200px) {
                        min-width: 450px;
                    }

                    @include breakpoint-min(1400px) {
                        min-width: 550px;
                    }

                    @include breakpoint-min(1600px) {
                        min-width: 650px;
                    }
                    textarea {
                        border: 1px solid $border;
                        padding: 6px;
                        margin-right: 15px;
                        width: 100%;
                        resize: vertical;
                    }
                }
            }
        }
    }

    .translation-search-button {
        background-color: $white;
        &:hover {
            color: $white;
            background-color: $primary-color-hover;
        }
        height: auto;
    }

    .container {
        .translations-container {
            .translaton-header {
                @include flex(flex-start);
                align-items: center;
                h2 {
                    margin-right: 30px;
                    padding-top: 10px;
                    padding-bottom: 0;
                }
                .select-box {
                    width: 200px;
                }
            }

            div {
                &.indent {
                    padding: 20px;
                    @include shadow(0.5);
                }
                &.hidden {
                    display: none;
                }
            }
            h3,
            h4,
            h5 {
                margin-bottom: 10px;
            }
            h3 {
                margin-top: 40px;
                font-size: 1.6em;
            }
            h4 {
                margin-top: 20px;
                font-size: 1.4em;
            }
            h5 {
                font-size: 1.2em;
            }

            &.non-records {
                h2 {
                    margin-bottom: 30px;
                }
                h3 {
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.translation-log {
    &__popup {
        background-color: white;
        width: 1000px;
        @include shadow(0.5);
        @include centerfixed(-50%, -50%);
        &__container {
            padding: 20px;
            table {
                @include table;
                tr {
                    td,
                    th {
                        &:first-child,
                        &:last-child {
                            max-width: inherit;
                        }
                        &:first-child {
                            width: inherit;
                            text-align: left;
                        }
                        &:last-child {
                            width: 160px;
                        }
                    }
                    th {
                        padding: 10px;
                    }
                }
            }
            &--header {
                @include flex(space-between);
                margin-bottom: 5px;
            }
            &--actual-value {
                margin-bottom: 20px;

                > h4 {
                    display: inline-block;
                }
            }
            &__table {
                &--date {
                }
            }
        }
    }
}
