@mixin btn-colors($color, $background) {
    color: $color;
    background: $background;
    border-color: $background;

    &--outline {
        color: $black;
        background: transparent;
        border-color: $background;

        &.btn--icon {
            border-color: $content;
        }
    }
}

@mixin btn-colors-hover($color, $background) {
    &:hover,
    &--outline:hover {
        color: $color;
        background: $background;
        border-color: $background;

        &.btn--icon {
            border-color: $background;
        }
    }
}

.btn {
    vertical-align: middle;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 3px;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    padding: 12px 15px;
    @include transition(0.2s);

    &.btn--disabled,
    &:disabled {
        cursor: not-allowed;

        &,
        &:hover {
            color: $white;
            background: $inactive;
            border-color: $inactive;

            &.btn--outline {
                color: $inactive;
                background: transparent;
                border-color: $inactive;
            }
        }
    }

    &.btn--icon {
        padding: 0;
    }

    &.btn--rounded {
        border-radius: 1000px;
    }
}

.fonda-btn {
    @include btn-colors($white, $fonda-blue);
    @include btn-colors-hover($white, $fonda-blue-hover);
}

.danger-btn {
    @include btn-colors($white, $red);
    @include btn-colors-hover($white, $red-hover);
}

.success-btn {
    @include btn-colors($black, $green);
    @include btn-colors-hover($black, $green-hover);
    @include btn-colors(var(--success-color), var(--success-background-color));
    @include btn-colors-hover(var(--success-color-hover), var(--success-background-color-hover));
}

.primary-btn {
    @include btn-colors($white, $content);
    @include btn-colors-hover($white, $black);
    @include btn-colors(var(--primary-color), var(--primary-background-color));
    @include btn-colors-hover(var(--primary-color-hover), var(--primary-background-color-hover));
}

.secondary-btn {
    @include btn-colors($black, $content);
    @include btn-colors-hover($white, $black);
}

.hero-btn {
    background: transparent;
    color: $black;
    color: var(--hero-color);
    border-color: var(--hero-color);

    &:hover,
    &:focus {
        background: var(--hero-background-color-hover);
        color: var(--hero-color-hover);
        border-color: var(--hero-color-hover);
    }
}

// ICONS

@mixin icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    @include transition(0.5s);
}

$icons: (
    'pencil': $icon-pen,
    'trash': $icon-trash,
    'burger': $icon-burger,
    'cross': $icon-cross,
    'check': $icon-check,
);

$colors: (
    'white': $white,
    'black': $black,
);

@each $iconName, $iconSvg in $icons {
    .icon-#{$iconName} {
        @include icon;
        @include svg($iconSvg, $black, 16px);

        @each $cname, $cvalue in $colors {
            &--#{$cname} {
                @include svg($iconSvg, $cvalue, 16px);
            }
        }

        .btn:hover & {
            @include svg($iconSvg, $white, 16px);
        }
    }
}

.btn-hidden-placeholder {
    opacity: 0;
    cursor: initial;
    pointer-events: none;
    visibility: hidden;
}
