.popup {
    &__text {
        font-size: 1.1rem;
        text-align: center;
        padding-bottom: 15px;
        font-weight: 500;

        &--detail {
            font-style: italic;
            font-weight: 800;
        }
    }

    &.delete-internal-message {
        @include centerfixed(-50%, -50%);
        width: 390px;
        h2 {
            margin-bottom: 20px;
        }
        background-color: $white;
        padding: 15px;

        .actions {
            @include flex(space-between);
            button {
                cursor: pointer;
            }
        }
    }

    &.edit-fiscal-year-popup {
        @include centerfixed(-50%, -50%);
        padding: 20px;
        width: 500px;
        background-color: $white;
        @include centered-shadow(0.5);
        h2 {
            margin-bottom: 20px;
        }
        form {
            .form-item {
                float: none;

                .fonda-datepicker-icon {
                    bottom: 33px;
                }

                .fonda-datepicker-container {
                    bottom: -100px;
                    top: auto;
                }
            }
        }

        .popup-header {
            @include flex(space-between);
        }

        .actions {
            @include flex(space-between);
            align-items: center;

            button {
                cursor: pointer;
                &:disabled {
                    background-color: $inactive;
                    &:hover {
                        background-color: $inactive;
                    }
                }
            }

            a {
                color: $red;
                &:hover {
                    color: $red-hover;
                    text-decoration: underline;
                }
            }
        }
    }

    &.approve-popup {
        background-color: $white;
        width: 100%;
        overflow: auto;
        &.small {
            max-width: 400px;
        }

        &.medium {
            max-width: 700px;
        }

        &.big {
            max-width: 1000px;
        }
        .popup-header {
            @include flex(space-between);
            margin-bottom: 20px;
        }
        h2 {
            padding-bottom: 0;
            width: calc(100% - 40px);
        }

        p {
            margin-bottom: 15px;
            padding: 0;
            text-align: left;
        }
        @include centerfixed(-50%, -50%);
        padding: 15px;
        .actions {
            @include flex(flex-end);
            align-items: center;

            button {
                cursor: pointer;
                background-color: $white;
                color: $black;
                margin: 0;
                &:hover {
                    background-color: $primary-color-hover;
                    color: $white;
                }
                &.color-success-black {
                    background-color: $green;
                    background-color: var(--success-background-color-hover);
                }

                &.hidden {
                    visibility: hidden;
                }
            }

            a {
                margin-left: 15px;
                color: $red;
                &:hover {
                    color: $red-hover;
                    text-decoration: underline;
                }
            }
        }

        &.formula {
            width: 500px;
            .mat-tab-label {
                width: 50%;
            }
        }
    }
}

.bigger-popup {
    .popup {
        &.approve-popup {
            width: 100%;
            max-width: 600px;
            h2 {
                margin: 0 0 20px 0;
                padding-bottom: 0;
                font-size: 2rem;
            }
        }
    }
}
.applicant {
    .popup {
        &.approve-popup {
            .actions {
                button {
                    &:hover {
                        background-color: $applicant-primary-color-hover;
                    }
                    &.color-success-black {
                        &:hover {
                            background-color: $green-hover;
                            background-color: var(--success-background-color);
                            color: $black;
                            color: var(--success-color);
                        }
                    }
                }
            }
        }
    }
}
