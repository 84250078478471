.error-box-container {
    padding: 30px 50px;
    width: calc(100% - 200px);
    border-radius: 20px;
    @include scalefixed(1, -50%, 10%);
    background-color: rgb(255, 149, 128);
    border: 2px solid $red;
    z-index: 10000;
    visibility: hidden;
    position: fixed;
    opacity: 0;
    @include shadow(0.3);
    @include transition(0.5s);
    transform-origin: center;
    @include scale(0.1);
    header {
        width: 100%;
        text-align: center;
        font-size: 2em;
        color: $red;
        margin-bottom: 15px;
    }
    &.active {
        transform-origin: center;
        @include scalefixed(1, -50%, 10%);
        top: 0;
        visibility: visible;
        opacity: 1;
    }

    p {
        text-align: center;
        color: $red;
        b {
            font-weight: bold;
        }
    }

    a {
        &.close-btn {
            display: inline-block;
            position: absolute;
            right: 0;
            border-radius: 50%;
            top: 0;
            &:hover {
                background-color: $red;
            }
        }
    }
    top: 0;
}

.draft-page-error-box {
    padding: 20px;
    background-color: rgba(229, 31, 58, 0.5);
    color: $white;
    text-align: center;
    position: relative;
    height: 0;
    visibility: hidden;

    &.active {
        height: auto;
        visibility: visible;
    }

    &:hover {
    }

    b {
        font-weight: bold;
    }

    p {
        color: $white;
        padding: 5px 0;
    }

    a {
        &.close-btn {
            display: inline-block;
            position: absolute;
            right: 0;
            top: 0;
            &:hover {
                background-color: $red;
            }
        }
    }
}

section {
    &.page-content {
        .draft-page-error-box {
            p {
                padding: 5px 0;
            }
        }
    }
}

.error-message {
    color: $red;
}
