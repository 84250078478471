.correspondence-popup {
    max-width: 900px;
    width: 100%;
    z-index: 9999;
    overflow: auto;
    background-color: rgba(255, 255, 255, 1);
    visibility: hidden;
    opacity: 0;
    @include shadow(0.3);
    @include scale(0);
    @include transition(0.5s);
    @include centerfixed(-50%, -50%);
    &.active {
        opacity: 1;
        visibility: visible;
        display: inline-block;
    }

    .correspondence-container {
        padding: 10px;
        label {
            width: 100%;
            position: relative;
        }

        form {
            position: relative;
            label {
                top: 25px;
            }
        }

        input,
        select {
            width: 100%;
        }

        .button {
            position: absolute;
            right: 0;
            top: 10px;
        }

        .action {
            position: relative;
            height: 50px;
        }
        .mat-tab-header {
            margin-top: 5px;
            width: 100%;
            .mat-tab-label {
                width: 100%;
            }
            .mat-ink-bar {
                background-color: $primary-color-hover;
            }
        }

        &.no-options {
            .mat-tab-header {
                display: none;
            }
        }

        .pdf-creator {
            margin-bottom: 15px;
            .form-item {
                label {
                    position: absolute;
                }
            }
            .actions {
                @include flex(flex-end);
                .button {
                    position: relative;
                    right: 0;
                    top: 0;
                    margin: 0;
                    margin-left: 10px;
                }
            }
        }
    }

    form {
        h2 {
            padding: 15px;
        }
        .artwork-image {
            p {
                font-size: 0.8em;
            }

            span {
                display: block;
                width: 100%;
                font-style: italic;
            }
        }

        .dialogue {
            @include dialogue;
        }

        .texting {
            margin: 15px 0;
            width: 100%;
            vertical-align: top;
            padding: 0 15px;
            &.popup {
                padding: 0;
            }

            .actions {
                .button {
                    margin-top: 15px;
                    position: relative;
                    top: 0;
                }
            }
            > div {
                label {
                    top: 0;
                }
            }

            textarea {
                width: 100%;
                height: 100px;
                border: 1px solid $border;
                background-color: $alt-border;
                margin-right: 15px;
                padding: 15px;
                line-height: 1.5;
                outline: none;
                resize: none;

                &::-webkit-input-placeholder,
                &::-ms-input-placeholder,
                &:-ms-input-placeholder {
                    color: $content;
                }
            }

            a {
                margin-top: 0;

                &.icon-image,
                &.icon-attachment {
                    width: 16px;
                    height: 16px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    display: inline-block;
                }

                &.icon-image {
                    margin-left: 15px;
                    @include svg($icon-image, $content, 16px);
                }

                &.icon-attachment {
                    margin-left: 10px;
                    @include svg($icon-attachment, $content, 16px);
                }

                &:hover {
                    &.icon-image {
                        @include svg($icon-image, $primary-color, 16px);
                    }

                    &.icon-attachment {
                        @include svg($icon-attachment, $primary-color, 16px);
                    }
                }
            }
        }
    }
    @include breakpoint-min(768px) {
        .correspondence-container {
            padding: 20px;
        }
    }
}
