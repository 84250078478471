.loader-container {
    @include centerfixed(-50%, -50%);
    .loader {
        @include loader;
    }
}

.file-inload-container {
    position: relative;
    .overlay {
        position: absolute;
    }
    .file-loader-container {
        @include center(-50%, -50%);
        .file-loader {
            @include loader;
        }
    }
}

.export-file-loader {
    position: absolute;
    right: 102%;
    top: 50%;
    transform: translateY(-50%);

    .loader-mini {
        @include loader;
        width: 30px;
        height: 30px;
        border-width: 5px;
    }
}

.small-loader-container {
    position: relative;
    width: 25px;
    height: 25px;
    display: inline-block;
    .loader {
        @include loader;
        width: 100%;
        top: 0;
        left: 0;
        position: relative;
        height: 100%;
        border: 6px solid #f3f3f3;
        border-top: 6px solid #3498db;
    }

    &.center {
        @include center(-50%, -50%);
    }
}
