.admin-administration-economy {
    padding: 25px 30px 25px 30px;

    .economy-header {
        @include flex(space-between);
        padding-bottom: 20px;
        .actions {
            ul {
                display: flex;
                li {
                    margin-right: 10px;
                    display: inline-block;
                    position: relative;
                    .clear-button {
                        position: absolute;
                        right: 5px;
                        top: -18px;
                        cursor: pointer;
                        color: $red;
                        @include transition(0.5s);
                        font-size: 0.8em;

                        &:hover {
                            color: $red-hover;
                        }
                    }
                    a {
                        &.icon-refresh {
                            width: 38px;
                            height: 38px;
                            display: inline-block;
                            .refresh {
                                background-size: 15px;
                            }
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .economy-table {
        @include table();

        tr {
            font-size: 0.9em;
            td,
            th {
                text-align: right;
                padding: 10px 10px 15px 10px;
                &:first-child {
                    text-align: left;
                }

                .additional-information {
                    display: block;
                    font-size: 0.8em;
                    font-weight: normal;
                }

                .expand-icon {
                    background-size: 18px;
                    height: 20px;
                }
            }
            border-bottom: none;
            border-top: 1px solid $border;
            &:nth-child(2) {
                border: none;
            }
            &:last-child {
                font-weight: 500;
                color: $black-grey;
                td {
                    padding-top: 25px;
                }
                border-top: 1px solid $border;
            }
            &:first-child {
                th {
                    padding-bottom: 4px;
                    font-size: 0.7em;
                    font-weight: normal;
                }
            }
            &.sub-category {
                border-top: 1px solid $border;
                background-color: $alt-border;
                td {
                    padding: 10px 10px;
                    &:first-child {
                        padding-left: 30px;
                    }
                }
            }
        }
    }
    &.reversed {
        tr {
            color: $black;
            &:last-child {
                td {
                    padding-top: 10px;
                }
            }
        }
    }
}
