.application-review-wrapper {
    &.agenda,
    &.management,
    &.caseworker {
        .application-menu {
            z-index: 100;
            background-color: $white;
            width: 280px;
            position: fixed;
            height: calc(100vh - 70px);
            .gu-over {
                padding-top: 20px;
                padding-bottom: 20px;
            }
            .actions-container {
                @include flex(space-between);
                a {
                    margin: 0 !important;
                }
                > a {
                    margin-right: 10px !important;
                    &:last-child {
                        margin: 0;
                    }
                }
                &.left {
                    @include flex(flex-start);
                }
                &.right {
                    @include flex(flex-end);
                }
            }
            .new-section-block {
                margin: 15px;
                text-align: center;
                background-color: $border;
                padding: 20px 35px;
                &.gu-over {
                    background-color: $green;
                    background-color: var(--success-background-color);
                    padding: 20px 35px;
                }
            }

            .select-component {
                .option-component-container {
                    min-width: inherit;
                }
            }

            .gu-transit {
                display: none !important;
            }

            .list-item {
                display: none;
            }

            &.fixed {
                position: fixed;
                height: calc(100vh - 70px);
                top: 70px;
            }

            .notification-container {
            }

            nav ul li a {
                position: relative;
                @include flex(space-between);
                align-items: center;
                padding: 15px 15px 15px 20px;
            }
        }
    }

    &.sorting-block {
        margin-top: 0;
        .application-second-tab {
            margin-left: 280px;
        }

        .caseworker-container {
            > .application-flow {
                margin-bottom: 25px;
                @include flex(flex-start);
                align-items: center;
                > h3 {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 1.8em;
                }
                > span {
                    cursor: pointer;
                    display: inline-block;
                    color: $primary-color;
                    &:hover {
                        color: $primary-color-hover;
                        text-decoration: underline;
                    }
                }
            }

            > .edit-container {
                @include shadow(0.7);
                > div {
                    height: 80px;
                    @include flex(center);
                    align-items: center;
                    background-color: $border;
                    width: 100%;
                    button {
                        &.button {
                            width: 30%;
                            margin-left: 20px;
                            cursor: pointer;
                        }
                    }
                    select-component {
                        width: 30%;
                        .select-component {
                            background-color: $white;
                            width: 100%;
                            &:hover {
                                background-color: $primary-color-hover;
                            }

                            &.expanded {
                                background-color: $primary-color-hover;
                            }
                        }
                    }
                }
                ul {
                    li {
                        &:not(.agenda):not(.form-item) {
                            padding: 10px;
                            width: 100%;
                            @include flex(space-between);
                            > div {
                                display: inline-block;
                                height: 30px;
                                &:first-child {
                                    width: 100px;
                                }

                                &:nth-child(2) {
                                    width: 200px;
                                }

                                &:nth-child(3) {
                                    width: 200px;
                                }

                                &:nth-child(4) {
                                    width: 100px;
                                }

                                &:last-child {
                                    width: 140px;
                                    text-align: center;
                                }
                            }
                        }
                        border-bottom: 1px solid $border;
                    }

                    &:first-child {
                        li {
                            border-bottom: none;
                        }
                    }
                }
            }

            .gu-over {
                border: 2px dotted $green;
                border: 2px dotted var(--success-background-color);
            }

            .gu-transit {
                background-color: $border;
                &:not(.agenda) {
                    div {
                        display: none;
                        opacity: 0;
                    }
                }
            }

            ul {
                .trash-icon {
                    border: 1px solid $border;
                    border-radius: 3px;
                    @include transition(0.5s);
                    @include svg($icon-trash, $black, 15px);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;
                    &:hover {
                        border-color: $primary-color-hover;
                        background-color: $primary-color-hover;
                        @include svg($icon-trash, $white, 15px);
                    }
                }

                .document-icon {
                    border: 1px solid $border;
                    border-radius: 3px;
                    @include transition(0.25s);
                    @include svg($icon-document, $black, 15px);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;

                    &:hover {
                        border-color: $primary-color-hover;
                        background-color: $primary-color-hover;
                        @include svg($icon-document, $white, 15px);
                    }
                }

                .chevron-right {
                    border: 1px solid $border;
                    border-radius: 3px;
                    @include transition(0.25s);
                    @include svg($icon-arrow-right, $black, 15px);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;

                    &:hover {
                        border-color: $primary-color-hover;
                        background-color: $primary-color-hover;
                        @include svg($icon-arrow-right, $white, 15px);
                    }
                }

                .upload-icon {
                    position: relative;
                    border: 1px solid $border;
                    border-radius: 3px;
                    @include transition(0.25s);
                    @include svg($icon-attachment, $black, 15px);
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    cursor: pointer;

                    .notifications-count {
                        background-color: $green;
                        background-color: var(--success-background-color);
                    }
                    &:hover,
                    &.active {
                        border-color: $primary-color-hover;
                        background-color: $primary-color-hover;
                        @include svg($icon-attachment, $white, 15px);
                    }
                }
                .burger-button {
                    width: 30px;
                    height: 30px;
                    position: relative;
                    display: block;
                    .icon-burger {
                        width: 16px;
                        height: 16px;
                        @include svg($icon-burger, $black, 16px);
                        @include center(-50%, -50%);
                    }
                }

                .pencil-button {
                    cursor: pointer;
                    display: inline-block;
                    background-color: $white;
                    .icon-pencil {
                        background-size: 16px;
                    }
                    &:hover {
                        background-color: $primary-color-hover;
                    }
                }
            }
        }

        &.agenda {
            margin-top: 70px;
            .application-menu {
                position: fixed;
            }
        }
    }
    agenda-sorting-block {
        width: 100%;
    }
}

li {
    &.gu-mirror {
        &:not(.agenda) {
            opacity: 1;
            @include shadow(1);
            padding: 10px;
            width: 100%;
            filter: none;
            @include flex(space-between);
            button {
                display: none;
            }
            div {
                display: inline-block;
                height: 30px;
                &:first-child {
                    width: 100px;
                }

                &:nth-child(2) {
                    width: 200px;
                }

                &:nth-child(3) {
                    width: 200px;
                }

                &:nth-child(4) {
                    width: 100px;
                }

                &:last-child {
                    width: 100px;
                }
                &.list-container {
                    width: 100%;
                    padding: 10px;
                    height: initial !important;
                    @include flex(space-between);
                }
            }
            border-bottom: 1px solid $border;
        }
    }
}
