//
// Pagination
//
.pagination {
    display: table;
    margin: 0 auto 30px;

    &.bottom {
        margin-top: 50px;
    }

    li {
        display: inline-block;
        vertical-align: top;
        margin: 0 2px;

        a {
            display: inline-block;
            line-height: 50px;
            text-align: center;
            color: $content;
            padding: 0 10px;

            &.active {
                color: $black;
                font-weight: 700;
                border-bottom: 2px solid $primary-color;
            }

            &.icon-arrow-left {
                @include svg($icon-arrow-left, $primary-color, 16px);
                width: 20px;
                height: 50px;

                &.inactive {
                    @include svg($icon-arrow-left, $placeholder, 16px);
                }
            }

            &.icon-arrow-right {
                @include svg($icon-arrow-right, $primary-color, 16px);
                width: 20px;
                height: 50px;

                &.inactive {
                    @include svg($icon-arrow-right, $placeholder, 16px);
                }
            }

            &:hover {
                background-color: $alt-border;
                font-weight: 700;
            }
        }
    }
}

.options-container {
    margin-bottom: 40px;
    @include flex(space-between);

    &.block {
        position: absolute;
        display: block;
        top: 40px;
        right: 50px;
    }
    .select-component {
        cursor: pointer;
        &:hover {
            background-color: $primary-color-hover;
            color: white;
        }
        &:after {
            top: 30%;
        }
        .option-component-container {
            &:hover {
                border: 1px $border solid;
            }
        }
        .option-component {
            color: black;
            &:hover {
                color: white;
            }
        }
        padding: 14px 15px;
        padding-right: 30px;
        border: 1px solid #cccccc;
        border-radius: 3px;
        .option-component-container {
            div {
                display: block;
            }
        }
    }
    ul {
        li {
            cursor: pointer;
            display: inline-block;
            &.active {
                border-bottom: 1px $primary-color solid;
            }
        }
    }

    div {
        display: inline-block;
    }

    a {
        &.options {
            @include svg($icon-settings, $black, 20px);
            width: 60px;
            height: 50px;
            vertical-align: bottom;
        }
    }
}
