@import 'general/inline-svgs';
@import 'general/colors';

.applicant {
    $primary-color: $content;
    $primary-color-hover: $black;

    $primary-button-color: $green;
    $primary-button-color-hover: $green-hover;
}

@import 'general/mixins';
@import 'general/svg';
@import 'general/typography';
@import 'general/values';

@import 'base/forms';
@import 'base/table';
@import 'base/buttons';
@import 'base/badge';

@import 'elements/dialogue';
@import 'elements/header';
@import 'elements/footer';
@import 'elements/formula';
@import 'elements/hero';
@import 'elements/article';
@import 'elements/process';
@import 'elements/lists';
@import 'elements/cards';
@import 'elements/user-application-flow';
@import 'elements/info-bar';
@import 'elements/application-overview';
@import 'elements/communication';
@import 'elements/date-picker';
@import 'elements/support-pages';
@import 'elements/admin-application-review';
@import 'elements/admin-application-bar';
@import 'elements/admin-application-tools';
@import 'elements/admin-mobile-deactivated';
@import 'elements/administration-menu';
@import 'elements/bottom-info-bar';
@import 'elements/application-menu';
@import 'elements/agenda-list';
@import 'elements/search';
@import 'elements/checkbox';
@import 'elements/warning-box';
@import 'elements/toaster';
@import 'elements/dropdowns';
@import 'elements/error-box';
@import 'elements/pagination';
@import 'elements/correspondence';
@import 'elements/admin-user-search-bar';
@import 'elements/admin-administration-blocks';
@import 'elements/popup';
@import 'elements/loader';
@import 'elements/records';
@import 'elements/notifications';
@import 'elements/select-component';
@import 'elements/admin-economy';
@import 'elements/record-filters';
@import 'elements/fonda-datepicker';
@import 'elements/admin-administration-general';
@import 'elements/economy-section';
@import 'elements/translation-manager';
@import 'elements/column-picker';
@import 'elements/sorting-block';
@import 'elements/payment';
@import 'elements/granted-amount';
@import 'elements/cookies';

@import 'components/ckeditor';

ul {
    list-style: none;
}

*,
*:after,
*:before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
    border: none;
}

body {
    width: 100%;
    background-color: $white;
    color: $content;
    overflow-x: hidden;
    overflow-y: scroll;

    &.modal-open {
        overflow-y: hidden;
    }

    &:not(.without-padding) {
        padding-bottom: 75px; // for impersonator banner and chat icon
    }
}

.container {
    position: relative;
    width: calc(100% - 10px);
    margin: 0 auto;
}

.body-wrapper {
    &.big-header {
        padding-top: 130px;
    }
}

section {
    width: 100%;
    display: table;

    &.bg-lightblue {
        background-color: $alt-white;
    }

    &.page-content {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 60px;

        h1,
        p {
            text-align: center;
        }

        p {
            padding: 20px 0;

            span {
                &.icon-form-error {
                    color: $red;
                }
            }
        }
    }

    &.first-section {
        margin-top: 70px;

        .content-center {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
        }
    }
}

.p-relative {
    position: relative;
}

@media screen and (max-width: 1023px) {
    .caseworker-area {
        display: none;
    }
}

@media screen and (min-width: $desktop) {
    .container {
        width: calc(100% - 60px);

        &.log {
            width: calc(100% - 30px);
        }
    }

    section {
        &.first-section {
            &.big-header {
                &.fixed {
                    margin-top: 0;
                    top: 0px;
                }
            }

            &.review,
            &.profile {
                margin-top: 130px;
            }
        }
    }
}
