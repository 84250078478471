//
// Colors
//
$white: #ffffff;
$black-grey: #555555;
$black: #333333;
$blackest: #000000;
$fonda-blue: #2980b9;
$blue-07: rgba(41, 128, 185, 0.41);
//$primary-color: #888888;
$fonda-blue-hover: #236fa1;
//$primary-color-hover: #333333;
$red: #e4254e;
$red-hover: #c92045;
$green: #add5ae;
$green-hover: #99bd99;
$content: #888888;
$placeholder: #999999;
$border: #cccccc;
$alt-border: #f2f2f2;
$inactive: #777777;
$lightgray: #e8ebed;
$alt-white: #f3f6f9;
$list-bg: #e8ecf2;
$table-title: #e6e6e6;

$tangerine: #f9812a;
$light-orange: #fecba3;

$green-darker: #5cae5c;
$green-darkest: #3c7c3c;
$light-green: #a2d0a2;
$text-editor-toolbar-color: #f1f1f1;

$primary-color: $fonda-blue;
$primary-color-hover: $fonda-blue-hover;
$applicant-primary-color: $content;
$applicant-primary-color-hover: $black;
